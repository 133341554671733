import React from 'react'
import { Link } from 'react-admin'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import pure from 'recompose/pure'

const resolveReference = (record, reference) => {
  if (typeof reference === 'function') {
    return reference(record)
  } else {
    return reference
  }
}

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation()

const LinkResourceField = ({
  className,
  source,
  record = {},
  reference,
  display
}) => (
  <Link
    className={className}
    to={`${resolveReference(record, reference)}/${get(record, source)}`}
    onClick={stopPropagation}
  >
    {get(record, display)}
  </Link>
)

LinkResourceField.defaultProps = { addLabel: true }

LinkResourceField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
  reference: PropTypes.any.isRequired,
  display: PropTypes.any
}

const PureLinkResourceField = pure(LinkResourceField)

export default PureLinkResourceField
