import { BaseProcessor } from './baseProcessor'

const endpoint = 'divisions'

const fields = {
  property_id: { required: true, key: 'id' },
  water: { required: true, key: 'config.features.utilities.water' },
  gas: { required: true, key: 'config.features.utilities.gas' },
  electric: { required: true, key: 'config.features.utilities.electric' }
}

export default new BaseProcessor(endpoint, fields)
