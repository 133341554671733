import React from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core'

export default function Activity({ record: { versions = [] } = {} }) {
  return (
    <Card style={{ marginTop: 20 }}>
      <CardHeader title="Activity" />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Agent</TableCell>
              <TableCell>Activity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {versions.map((version, idx) => (
              <TableRow key={`version-${idx}`}>
                <TableCell>
                  {new Date(version.created_at).toLocaleString()}
                </TableCell>
                <TableCell>
                  {version.user
                    ? `${version.user.first_name} ${version.user.last_name}`
                    : null}
                </TableCell>
                <TableCell>
                  {Object.keys(version.object_changes).map(key => (
                    <div key={`version-${idx}-${key}`}>
                      Changed the {key} to{' '}
                      {JSON.stringify(version.object_changes[key][1])}
                    </div>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
