import React, { Fragment, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Notification, useLogin } from 'react-admin'
import { Grid, Button, Card, CardContent } from '@material-ui/core'
import { useCookies } from 'react-cookie'

const apiUrl = process.env.UPD_API_URL

const LoginPage = withRouter(({ location: { search } }) => {
  const login = useLogin()
  const [cookies] = useCookies(['auth0.token'])

  useEffect(() => {
    if (cookies['auth0.token']) {
      login({accessToken: cookies['auth0.token']})
    }
  }, [cookies['auth0.token']])

  return (
    <Fragment>
      <Notification />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={4}>
          <Card style={{ marginTop: 50 }}>
            <CardContent
              style={{ justifyContent: 'center', textAlign: 'center' }}
            >

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  )
})

export default LoginPage
