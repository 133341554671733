import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-admin'
import { bulkProcess } from '../../actions/bulkProcess'

class CancelInvitesButton extends Component {
  handleClick = () => {
    const { basePath, selectedIds, bulkProcess } = this.props
    bulkProcess(
      basePath,
      { data: selectedIds.map(id => ({ id, status: 'cancelled' })) },
      true
    )
  }

  render() {
    return <Button label="Cancel" onClick={this.handleClick} />
  }
}

export default connect(undefined, { bulkProcess })(CancelInvitesButton)
