import { UPDATE } from 'react-admin'

export const INVITE_CANCEL = 'INVITE_CANCEL'
export const inviteCancel = (data, basePath) => ({
  type: INVITE_CANCEL,
  payload: {
    id: data.id,
    previousData: data,
    data: { status: 'cancelled' }
  },
  meta: {
    fetch: UPDATE,
    resource: 'invites',
    onSuccess: {
      notification: {
        body: 'Invite Successfully Cancelled',
        level: 'info'
      },
      redirectTo: '/invites',
      basePath
    },
    onFailure: {
      notification: {
        body: 'Invite could NOT be cancelled.',
        level: 'warning'
      }
    }
  }
})
