import gql from 'graphql-tag'

export default function(raFetchType, params) {
  switch (raFetchType) {
    case 'GET_ONE':
      return {
        query: gql`
	      query {
          entityItemDefinition(id: ${params.id}) {
            entityItemDefinition {
              id
              kind
              entityId
              itemDefinitionId
              enabled
              reason
            }
          }
        }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.entityItemDefinition.entityItemDefinition
        })
      }
    case 'GET_LIST':
      const pagination = `
        page: ${params.pagination.page}
        perPage: ${params.pagination.perPage}
      `
      const input = Object.keys(params.filter || {}).reduce(
        (acc, key) => `
        ${acc}
        ${key}: ${params.filter[key]}
      `,
        pagination
      )
      return {
        query: gql`
          query {
            entityItemDefinitions(${input}) {
              entityItemDefinitions {
                id
                kind
                entityId
                itemDefinitionId
                enabled
                reason
              }
              dataView {
                pagination {
                  recordCount
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.entityItemDefinitions.entityItemDefinitions,
          total:
            response.data.entityItemDefinitions.dataView.pagination.recordCount
        })
      }
    case 'UPDATE':
    case 'CREATE':
      delete params.data.__typename
      return {
        query: gql`
          mutation UpsertEntityItemDefinition(
            $input: UpsertEntityItemDefinitionInput!
          ) {
            upsertEntityItemDefinition(input: $input) {
              entityItemDefinition {
                id
                kind
                entityId
                itemDefinitionId
                enabled
                reason
              }
            }
          }
        `,
        variables: {
          input: params.data
        },
        parseResponse: response => ({
          data: response.data.upsertEntityItemDefinition.entityItemDefinition,
          total: 1
        })
      }
    case 'DELETE':
      return {
        query: gql`
          mutation DeleteEntityItemDefinition {
            deleteEntityItemDefinition(id: ${params.id}) {
              entityItemDefinition {
                id
                kind
                entityId
                itemDefinitionId
                enabled
                reason
              }
            }
          }
        `,
        variables: params,
        parseResponse: response => ({
          data: response.data.deleteEntityItemDefinition.entityItemDefinition,
          total: 1
        })
      }
  }
}
