import React from 'react'
import {
  Edit,
  List,
  Datagrid,
  TextField,
  EmailField,
  Filter,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  email
} from 'react-admin'

export const ManagerFilter = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" defaultValue="" />
    <TextInput label="id" source="id" defaultValue="" />
  </Filter>
)

export const ManagerList = props => (
  <List {...props} filters={<ManagerFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <TextField source="first_name" label="First Name" />
      <TextField source="last_name" label="Last Name" />
      <EmailField source="email" label="Email" />
      <TextField source="proper_email" label="Proper Email" />
      <TextField source="roles[0].name" label="Role" />
    </Datagrid>
  </List>
)

export const ManagerUpsert = props => (
  <Edit title="Edit Manager" {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <ReferenceInput
        label="Role"
        source="roles[0].id"
        reference="roles"
        filter={{ app: 'company' }}
      >
        <SelectInput />
      </ReferenceInput>
      <TextInput label="First Name" source="first_name" validate={required()} />
      <TextInput label="Last Name" source="last_name" validate={required()} />
      <TextInput
        label="Email"
        source="email"
        type="email"
        validate={[required(), email()]}
      />
    </SimpleForm>
  </Edit>
)
