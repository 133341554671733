import gql from 'graphql-tag'
import { sanitizeParams } from './helpers'

const upsertUtilityProviderPermittedParams = [
  'id',
  'kind',
  'name',
  'localPhone',
  'businessPhone',
  'email',
  'website',
  'state',
  'externalId',
  'source'
]

const utility = `
  id
  kind
  name
  localPhone
  businessPhone
  email
  website
  state
  externalId
  source
`
const utilityUpsert = data => ({
  query: gql`
      mutation UpsertUtilityProvider($input: UpsertUtilityProviderInput!) {
        upsertUtilityProvider(input: $input) {
          utility {
            ${utility}
          }
        }
      }
    `,
  variables: {
    input: sanitizeParams(data, upsertUtilityProviderPermittedParams)
  },
  parseResponse: response => ({
    data: response.data.upsertUtilityProvider.utility,
    total: 1
  })
})

export default function(raFetchType, params) {
  switch (raFetchType) {
    case 'GET_ONE':
      return {
        query: gql`
          query {
            searchUtilityProviders(
              input: {keyword: "${params.id}"}
            ) {
              ${utility}
            }
          }
        `,
        variables: {},
        parseResponse: response => ({
          data: response.data.searchUtilityProviders[0],
          total: 1
        })
      }
      break
    case 'GET_LIST':
      const list_input = Object.entries(params.filter)
        .map(([k, v]) => `${k}: ${k === 'kind' ? v : `"${v}"`}`)
        .join(' ')
      return {
        query: gql`
          query {
            searchUtilityProviders(
              input: {${list_input}}
            ) {
              ${utility}
            }
          }
        `,
        variables: {},
        parseResponse: response => ({
          data: response.data.searchUtilityProviders,
          total: 1
        })
      }
      break
    case 'UPDATE':
      delete params.data.__typename
      return utilityUpsert(params.data)
      break
    case 'CREATE':
      delete params.data.__typename
      params.data.source = 'EDWIN'
      return utilityUpsert(params.data)
      break
  }
}
