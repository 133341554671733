import React, { Fragment } from 'react'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import RichTextInput from 'ra-input-rich-text'
import { STEPS } from '../constants/steps'
import { withStyles } from '@material-ui/core'
import './banner_html.css'

const style = {
  details: {
    display: 'block',
    'min-height': '150px'
  },
  tips: {
    color: 'red',
    'font-size': '0.8em',
    'margin-bottom': '0.5em'
  }
}

// Remove <p> tags and wrap content with <strong>
const htmlParser = v =>
  v ? `<strong>${v.replace(/<\/?p>/gi, '')}</strong>` : ''

// Remove <strong>
const stripBold = v => (v ? v.replace(/<\/?strong>/gi, '') : '')

export const BannerHtmlInput = withStyles(style)(({ classes }) => (
  <Fragment>
    <ExpansionPanel className="banner-html">
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        Banner Html
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="ql-container">
        <div className={classes.tips}>*Line breaks will be ignored</div>
        {STEPS.map(step => (
          <ExpansionPanel key={step}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              {step}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <RichTextInput
                source={`config.banner_html.${step}`}
                parse={htmlParser}
                format={stripBold}
                toolbar={['link']}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </Fragment>
))
