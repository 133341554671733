export const STATUSES = {
  xfinity: {
    pending: ['call_attempted', 'after_hours'],
    all: [
      'ready',
      'working',
      'completed_unassisted',
      'call_attempted',
      'after_hours',
      'next_day_call_attempted',
      'completed_assisted',
      'cancelled',
      'expired'
    ],
    uneditable: ['ready', 'expired'],
    errorRequired: [
      'call_attempted',
      'after_hours',
      'completed_assisted',
      'cancelled'
    ],
    noteRequired: ['completed_assisted'],
    final: ['completed_assisted', 'completed_unassisted', 'cancelled']
  },
  att: {
    pending: ['call_attempted', 'after_hours'],
    all: [
      'ready',
      'working',
      'completed_unassisted',
      'call_attempted',
      'after_hours',
      'next_day_call_attempted',
      'completed_assisted',
      'cancelled',
      'expired'
    ],
    uneditable: ['ready', 'expired'],
    errorRequired: [
      'call_attempted',
      'after_hours',
      'completed_assisted',
      'cancelled'
    ],
    noteRequired: ['completed_assisted'],
    final: ['completed_assisted', 'completed_unassisted', 'cancelled']
  },
  cox: {
    pending: ['call_attempted', 'after_hours'],
    all: [
      'ready',
      'working',
      'completed_unassisted',
      'call_attempted',
      'after_hours',
      'next_day_call_attempted',
      'completed_assisted',
      'cancelled',
      'expired'
    ],
    uneditable: ['ready', 'expired'],
    errorRequired: [
      'call_attempted',
      'after_hours',
      'completed_assisted',
      'cancelled'
    ],
    noteRequired: ['completed_assisted'],
    final: ['completed_assisted', 'completed_unassisted', 'cancelled']
  },
  spectrum: {
    pending: ['call_attempted', 'after_hours'],
    all: [
      'ready',
      'working',
      'completed_unassisted',
      'call_attempted',
      'after_hours',
      'next_day_call_attempted',
      'completed_assisted',
      'cancelled',
      'expired'
    ],
    uneditable: ['ready', 'expired'],
    errorRequired: [
      'call_attempted',
      'after_hours',
      'completed_assisted',
      'cancelled'
    ],
    noteRequired: ['completed_assisted'],
    final: ['completed_assisted', 'completed_unassisted', 'cancelled']
  }
}

export const ERROR_TYPES = {
  xfinity: {
    cancelled: [
      { id: 'credit_check', name: 'Credit check' },
      { id: 'past_due_balance', name: 'Past due balance' },
      { id: 'provider_system_error', name: 'Provider system error' },
      {
        id: 'contacted_provider_directly',
        name: 'Contacted provider directly'
      },
      { id: 'changed_mind', name: 'Changed mind' },
      { id: 'bad_contact_info', name: 'Bad contact info' },
      { id: 'invalid_credit_card', name: 'Invalid credit card' },
      { id: 'credit_card_name_issue', name: 'Credit card name issue' },
      { id: 'other', name: 'Other' }
    ],
    pending: [
      {
        id: 'offer_not_available',
        name: 'Offer is not available'
      },
      {
        id: 'incorrect_offer_price',
        name: 'Offer price is incorrect'
      },
      {
        id: 'moderm_not_available',
        name: 'Modem is not available'
      },
      {
        id: 'self_install_not_available',
        name: 'Self Install is not available'
      },
      {
        id: 'pre_payment_amount_incorrect',
        name: 'Pre payment amount is incorrect'
      },
      {
        id: 'deposit_amount_incorrect',
        name: 'Deposit amount is incorrect'
      },
      {
        id: 'credit_card_failed',
        name: 'Credit card failed'
      },
      {
        id: 'past_due_account',
        name: 'Past due account'
      },
      {
        id: 'other',
        name: 'Other'
      }
    ]
  },
  att: {
    cancelled: [
      { id: 'credit_check', name: 'Credit check' },
      { id: 'past_due_balance', name: 'Past due balance' },
      { id: 'provider_system_error', name: 'Provider system error' },
      {
        id: 'contacted_provider_directly',
        name: 'Contacted provider directly'
      },
      { id: 'changed_mind', name: 'Changed mind' },
      { id: 'bad_contact_info', name: 'Bad contact info' },
      { id: 'invalid_credit_card', name: 'Invalid credit card' },
      { id: 'credit_card_name_issue', name: 'Credit card name issue' },
      { id: 'other', name: 'Other' }
    ],
    pending: [
      {
        id: 'offer_not_available',
        name: 'Offer is not available'
      },
      {
        id: 'incorrect_offer_price',
        name: 'Offer price is incorrect'
      },
      {
        id: 'credit_card_failed',
        name: 'Credit card failed'
      },
      {
        id: 'past_due_account',
        name: 'Past due account'
      },
      {
        id: 'credit_check_failure',
        name: 'Credit check failure'
      },
      {
        id: 'credit_check_risk',
        name: 'Credit check risk'
      },
      {
        id: 'self_install_not_available',
        name: 'Self Install is not available'
      },
      {
        id: 'pro_install_not_available',
        name: 'Pro Install is not available'
      },
      {
        id: 'incorrect_install_price',
        name: 'Install price is incorrect'
      },
      {
        id: 'install_window_not_available',
        name: 'Installation windows not available'
      },
      {
        id: 'activation_date_not_available',
        name: 'Activation dates not available'
      },
      {
        id: 'other',
        name: 'Other'
      }
    ]
  },
  cox: {
    cancelled: [
      { id: 'credit_check', name: 'Credit check' },
      { id: 'past_due_balance', name: 'Past due balance' },
      { id: 'provider_system_error', name: 'Provider system error' },
      {
        id: 'contacted_provider_directly',
        name: 'Contacted provider directly'
      },
      { id: 'changed_mind', name: 'Changed mind' },
      { id: 'bad_contact_info', name: 'Bad contact info' },
      { id: 'invalid_credit_card', name: 'Invalid credit card' },
      { id: 'credit_card_name_issue', name: 'Credit card name issue' },
      { id: 'other', name: 'Other' }
    ],
    pending: [
      {
        id: 'offer_not_available',
        name: 'Offer is not available'
      },
      {
        id: 'incorrect_offer_price',
        name: 'Offer price is incorrect'
      },
      {
        id: 'credit_card_failed',
        name: 'Credit card failed'
      },
      {
        id: 'past_due_account',
        name: 'Past due account'
      },
      {
        id: 'credit_check_failure',
        name: 'Credit check failure'
      },
      {
        id: 'credit_check_risk',
        name: 'Credit check risk'
      },
      {
        id: 'self_install_not_available',
        name: 'Self Install is not available'
      },
      {
        id: 'pro_install_not_available',
        name: 'Pro Install is not available'
      },
      {
        id: 'incorrect_install_price',
        name: 'Install price is incorrect'
      },
      {
        id: 'install_window_not_available',
        name: 'Installation windows not available'
      },
      {
        id: 'activation_date_not_available',
        name: 'Activation dates not available'
      },
      {
        id: 'other',
        name: 'Other'
      }
    ]
  },
  spectrum: {
    cancelled: [
      { id: 'credit_check', name: 'Credit check' },
      { id: 'past_due_balance', name: 'Past due balance' },
      { id: 'provider_system_error', name: 'Provider system error' },
      {
        id: 'contacted_provider_directly',
        name: 'Contacted provider directly'
      },
      { id: 'changed_mind', name: 'Changed mind' },
      { id: 'bad_contact_info', name: 'Bad contact info' },
      { id: 'invalid_credit_card', name: 'Invalid credit card' },
      { id: 'credit_card_name_issue', name: 'Credit card name issue' },
      { id: 'other', name: 'Other' }
    ],
    pending: [
      {
        id: 'offer_not_available',
        name: 'Offer is not available'
      },
      {
        id: 'incorrect_offer_price',
        name: 'Offer price is incorrect'
      },
      {
        id: 'credit_card_failed',
        name: 'Credit card failed'
      },
      {
        id: 'past_due_account',
        name: 'Past due account'
      },
      {
        id: 'credit_check_failure',
        name: 'Credit check failure'
      },
      {
        id: 'credit_check_risk',
        name: 'Credit check risk'
      },
      {
        id: 'self_install_not_available',
        name: 'Self Install is not available'
      },
      {
        id: 'pro_install_not_available',
        name: 'Pro Install is not available'
      },
      {
        id: 'incorrect_install_price',
        name: 'Install price is incorrect'
      },
      {
        id: 'install_window_not_available',
        name: 'Installation windows not available'
      },
      {
        id: 'activation_date_not_available',
        name: 'Activation dates not available'
      },
      {
        id: 'other',
        name: 'Other'
      }
    ]
  }
}
