import {
  ArrayInput,
  BooleanInput,
  SimpleFormIterator,
  SelectInput,
  TextInput,
  required
} from 'react-admin'
import React, { Fragment } from 'react'
import { url } from 'redux-form-validators'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import { sanitizer } from '../formatters/sanitizer'
import { withStyles } from '@material-ui/core'
import { LogoUploadInput } from './logo_upload'

const style = {
  details: {
    display: 'block'
  }
}

export const RentersInsuranceInput = withStyles(style)(
  ({ classes, record }) => (
    <Fragment>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          Renters Insurance
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <BooleanInput
            label="Required"
            source="config.renters_insurance.required"
          />
          <TextInput
            label="Name"
            source="config.renters_insurance.name"
            options={{ fullWidth: true }}
          />
          <TextInput
            label="Description"
            source="config.renters_insurance.description"
            options={{ fullWidth: true }}
          />
          <TextInput
            label="CTA Link"
            source="config.renters_insurance.cta_url"
            options={{ fullWidth: true }}
            validate={url({ allowBlank: true })}
          />
          <ArrayInput
            label="Requirements"
            source="config.renters_insurance.requirements"
          >
            <SimpleFormIterator>
              <TextInput format={sanitizer} validate={required()} label=" " />
            </SimpleFormIterator>
          </ArrayInput>
          <LogoUploadInput
            record={(record.config || {}).renters_insurance || {}}
            sourcePrefix="config.renters_insurance"
          />
          <TextInput
            label="External Property ID (For leasing Desk)"
            source="config.renters_insurance.external_property_id"
            options={{ fullWidth: true }}
          />
          <SelectInput
            label="External Property ID Type (For leasing Desk)"
            source="config.renters_insurance.external_property_id_type"
            choices={[
              { id: 'OneSite', name: 'OneSite' },
              { id: 'Yardi', name: 'Yardi' }
            ]}
            options={{ fullWidth: true }}
          />
          <BooleanInput
            label="Jetty Enabled"
            source="config.renters_insurance.jetty_enabled"
          />
          <BooleanInput
            label="Exclude Sure"
            source="config.renters_insurance.exclude_sure"
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Fragment>
  )
)
