import React, { Component } from 'react'
import { Title } from 'react-admin'
import { Card, CardContent } from '@material-ui/core'
import { Redirect } from 'react-router'

import { hasPermission } from '../utils/permission'

export default class Dashboard extends Component {
  render() {
    return (
      <Card>
        {this.props.permissions ? (
          hasPermission(this.props.permissions, 'forward_mail', [
            'read',
            'update'
          ]) ? null : (
            <Redirect to="/home_services_order" />
          )
        ) : null}
        <Title title="Welcome To Edwin" />
        <CardContent>This tool is here to help.</CardContent>
        <CardContent>
          <a
            href="https://docs.google.com/presentation/d/1-54Gh7RFXrFCKawKySRkUBIZL-wi_OlhzMs-XKMTex0/edit#slide=id.g499ff5dcb8_0_4"
            rel="noreferrer noopener"
            target="_blank"
          >
            Intro to Edwin (Slides)
          </a>
        </CardContent>
        <CardContent>
          <a
            href="https://docs.google.com/spreadsheets/d/1Vg5PDdYGHCI6eIgNAkXLSh21L5i_GqyViZ2OgNHAxqc/edit?ts=5c05b0f4#gid=1427411253"
            rel="noreferrer noopener"
            target="_blank"
          >
            Report an issue/bug
          </a>
        </CardContent>
        <CardContent>
          <a
            href="https://docs.google.com/spreadsheets/d/1Vg5PDdYGHCI6eIgNAkXLSh21L5i_GqyViZ2OgNHAxqc/edit?ts=5c05b0f4#gid=2124633897"
            rel="noreferrer noopener"
            target="_blank"
          >
            Request a new config
          </a>
        </CardContent>
        <CardContent>
          <a
            href="https://docs.google.com/document/d/1ovxHIsT64bn7CbcYJo6XeykbCnM9XhD9ay8YpaV5q60/edit?usp=sharing"
            rel="noreferrer noopener"
            target="_blank"
          >
            Instruction Docs (in progress)
          </a>
        </CardContent>
        <CardContent>
          <a
            href="https://docs.google.com/spreadsheets/d/1Vg5PDdYGHCI6eIgNAkXLSh21L5i_GqyViZ2OgNHAxqc/edit"
            rel="noreferrer noopener"
            target="_blank"
          >
            List of supported functionality
          </a>
        </CardContent>
        <CardContent>
          <a
            href="https://docs.google.com/spreadsheets/d/1EhXFbvTMwtvzpx27RZIeJcaL1BqdMQ_hmggZKJDbVVE/edit"
            rel="noreferrer noopener"
            target="_blank"
          >
            Templates for bulk process
          </a>
        </CardContent>
      </Card>
    )
  }
}
