import {
  TopToolbar,
  Edit,
  Filter,
  List,
  SimpleForm,
  TextField,
  BooleanField,
  DateField,
  TextInput,
  SelectInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  DateInput,
  Create,
  required,
  BulkDeleteButton,
  DeleteButton
} from 'react-admin'
import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core'
import FullServiceMoveCancelButton from './buttons/fullServiceMoveCancel'
import { AddressField, WindowField } from './fields'
import { DateRangeFilter } from './filters'

import FullServiceMoveGrid from './fullServiceMoveGrid'
import classes from './fullServiceMove.module.css'
import { ALL_STATES } from '../utils/constances/states'

const stateList = ALL_STATES.map(state => ({ id: state, name: state }))

const inputStyle = {
  input_container: {
    'margin-top': '1em',
    padding: '1em',
    'box-shadow': '0px 1px 3px 0px rgba(0, 0, 0, 0.2)'
  },
  input_title: {
    color: 'rgba(0, 0, 0, 0.87)',
    'margin-bottom': '1em'
  }
}

const FullServiceMoveFilter = props => (
  <Filter {...props}>
    <TextInput source="confirmation_no" label="Confirmation Number" />
    <TextInput source="id" label="Id" />
    <TextInput source="move_id" label="Move Id" />
    <DateRangeFilter source="date" label="Date" />
    <SelectInput
      source="window"
      label="Window"
      choices={[
        { id: 'am', name: 'am' },
        { id: 'pm', name: 'pm' }
      ]}
    />
    <BooleanInput source="booked" label="Booked" />
  </Filter>
)

const FullServiceMoveBulkActionButtons = props => (
  <Fragment>{!props.move_id && <BulkDeleteButton {...props} />}</Fragment>
)

export function FullServiceMoveList(props) {
  return (
    <List
      {...props}
      filters={<FullServiceMoveFilter />}
      bulkActionButtons={<FullServiceMoveBulkActionButtons />}
      sort={{ field: 'date', order: 'ASC' }}
      perPage={30}
    >
      <FullServiceMoveGrid {...props} rowClick="edit">
        <DateField
          source="date"
          label="Date"
          options={{
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
            timeZone: 'UTC'
          }}
        />
        <WindowField source="window" label="Window" />
        <BooleanField source="booked" label="Booked" />
        <AddressField source="from_address" label="From" showLabel={false} />
        <AddressField source="from_address" label="To" showLabel={false} />
        <TextField source="confirmation_no" label="Confirmation Number" />
        <TextField source="id" label="Id" />
        <TextField source="move_id" label="Move Id" />
        <FullServiceMoveCancelButton noLabel={true} width="50px" />
      </FullServiceMoveGrid>
    </List>
  )
}

function FullServiceMoveEditToolbar(props) {
  return (
    <Toolbar {...props}>
      <SaveButton />
      <FullServiceMoveCancelButton className={classes.deleteCancelButton} />
      <DeleteButton
        className={
          !props.record || !props.record.move_id
            ? classes.deleteCancelButton
            : classes.hide
        }
      />
    </Toolbar>
  )
}

const FullServiceMoveInput = withStyles(inputStyle)(() => (
  <Fragment>
    <TextField source="id" label="Id" />
    <TextField label="Confirmation Number" source="confirmation_no" />
    <DateInput source="date" label="Date" validate={required()} />
    <SelectInput
      source="window"
      label="Window"
      choices={[
        { id: 'am', name: 'am' },
        { id: 'pm', name: 'pm' }
      ]}
      validate={required()}
    />
  </Fragment>
))

const FullServiceMoveEditActions = () => <TopToolbar></TopToolbar>

export function FullServiceMoveEdit(props) {
  return (
    <Edit title="Edit Move" {...props} actions={<FullServiceMoveEditActions />}>
      <SimpleForm toolbar={<FullServiceMoveEditToolbar />}>
        <TextField label="Id" source="id" />
        <TextInput label="Move Id" source="move_id" />
        <TextInput label="Confirmation Number" source="confirmation_no" />
        <BooleanInput label="Booked" source="booked" />
        <label>Moving From:</label>
        <form style={inputStyle.input_container}>
          <TextInput label="street" source="from_address.street" />
          <TextInput label="Unit" source="from_address.apt" />
          <TextInput label="City" source="from_address.city" />
          <TextInput label="ZIP" source="from_address.zip" />
          <SelectInput
            label="State"
            source="from_address.state"
            choices={stateList}
          />
        </form>
        <br />
        <label>Moving To:</label>
        <form style={inputStyle.input_container}>
          <TextInput label="street" source="to_address.street" />
          <TextInput label="Unit" source="to_address.apt" />
          <TextInput label="City" source="to_address.city" />
          <TextInput label="ZIP" source="to_address.zip" />
          <SelectInput
            label="State"
            source="from_address.state"
            choices={stateList}
          />
        </form>

        <SelectInput
          source="window"
          label="Window"
          choices={[
            { id: 'am', name: 'am' },
            { id: 'pm', name: 'pm' }
          ]}
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  )
}

export const FullServiceMoveCreate = props => (
  <Create title="Create Local Move" {...props}>
    <SimpleForm>
      <FullServiceMoveInput />
    </SimpleForm>
  </Create>
)
