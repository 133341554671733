export const STEPS = [
  'forward_mail',
  'update_account',
  'utility',
  'home_service',
  'offer',
  'share',
  'resident_portal',
  'recommended_provider',
  'moving_center',
  'renters_insurance',
  'insurance_quote',
  'insurance_retention',
  'diy_move',
  'auto_insurance'
]
