import React, { Component } from 'react'
import {
  Create,
  Edit,
  minLength,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  email
} from 'react-admin'

class AdminInput extends Component {
  sharedRender() {
    return [
      <ReferenceInput
        label="Role"
        source="role_ids[0]"
        filter={{ app: 'admin' }}
        reference="roles"
        key="roles"
      >
        <SelectInput />
      </ReferenceInput>,
      <TextInput
        label="First Name"
        source="first_name"
        validate={required()}
        key="first_name"
      />,
      <TextInput
        label="Last Name"
        source="last_name"
        validate={required()}
        key="last_name"
      />,
      <TextInput
        label="Email"
        source="email"
        type="email"
        validate={[required(), email()]}
        key="email"
      />,
      <TextInput
        label="Password"
        source="password"
        type="password"
        key="password"
        validate={[minLength(6, 'Should be at least 6 characters')]}
      />
    ]
  }
}

export class AdminCreate extends AdminInput {
  render() {
    return (
      <Create title="Create Admin" {...this.props}>
        <SimpleForm>{this.sharedRender()}</SimpleForm>
      </Create>
    )
  }
}

export class AdminEdit extends AdminInput {
  render() {
    return (
      <Edit title="Create Admin" {...this.props}>
        <SimpleForm>
          <TextInput disabled label="Id" source="id" />
          {this.sharedRender()}
        </SimpleForm>
      </Edit>
    )
  }
}
