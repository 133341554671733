export const BULK_PROCESS = 'BULK_PROCESS'
export const bulkProcess = (path, data, continueOnFailure, callbackFunc) => ({
  type: BULK_PROCESS,
  payload: {
    previousData: data,
    data: { ...data, continue_on_failure: continueOnFailure }
  },
  meta: {
    fetch: BULK_PROCESS,
    resource: `bulk/${path}`,
    onSuccess: {
      notification: {
        body: `Bulk update was successful.`,
        level: 'info'
      },
      callback: ({ payload, requestPayload }) => {
        if (callbackFunc instanceof Function) {
          callbackFunc(payload, requestPayload)
        }
      },
      basePath: path,
      refresh: true,
      unselectAll: true
    },
    onFailure: {
      notification: {
        body: `At least one record was not able to be updated.`,
        level: 'info'
      },
      callback: ({ payload, requestPayload }) => {
        if (callbackFunc instanceof Function) {
          callbackFunc(payload, requestPayload)
        }
      },
      basePath: path,
      refresh: true,
      unselectAll: true
    }
  }
})
