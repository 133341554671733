import gql from 'graphql-tag'
import { sanitizeParams } from './helpers'

const outputTransformer = outputObj => {
  if (!outputObj.externalId) {
    outputObj.externalId = outputObj.externalIds
      ? outputObj.externalIds[0]
      : null
  }
  return outputObj
}

const upsertPromotionPermittedParams = [
  'id',
  'name',
  'code',
  'externalId',
  'forEntityId',
  'imageUrl',
  'forEntityExternalId',
  'status',
  {
    categories: []
  },
  {
    placements: []
  },
  {
    redemption: [
      {
        code: ['type', 'value', 'format'],
        methods: ['type', 'value', 'description']
      }
    ]
  },
  'customFields',
  {
    metadata: [
      'exclusive',
      'displayName',
      'description',
      'shortTerms',
      'fullTerms',
      'discount',
      'claimType',
      'contactPreferenceRequired',
      'order',
      {
        redemption: ['barcode', 'barcodeUrl', 'description']
      }
    ]
  },
  {
    rules: ['externalId', 'definition', 'description']
  }
]

const promotionQueryParams = params => {
  const paginationParams = {
    page: null,
    perPage: null,
    sort: null,
    search: null,
    status: null
  }
  if (params.pagination.page) {
    paginationParams.page = params.pagination.page
  }
  if (params.pagination.perPage) {
    paginationParams.perPage = params.pagination.perPage
  }
  if (params.sort.field && params.sort.order) {
    paginationParams.sort = `"${params.sort.field} ${params.sort.order}"`
  }
  if (params.filter.search) {
    paginationParams.search = `"${params.filter.search}"`
  }
  if (params.filter.status) {
    paginationParams.status = `[${params.filter.status}]`
  }
  return paginationParams
}

export default function(raFetchType, params) {
  const promotion = `
    id
    code
    externalIds
    name
    status
    forEntity {
      id
      name
    }
    categories
    placements
    image {
      url
    }
    customFields
    redemption {
      code {
        type
        value
        format
      }
      methods {
        type
        value
        description 
      }
    }
    metadata {
      displayName
      description
      shortTerms
      fullTerms
      discount
      order
      exclusive
      claimType
      contactPreferenceRequired
      redemption {
        description
      }
    }
    rules {
      id
      externalIds
      definition
      description
    }
  `
  switch (raFetchType) {
    case 'GET_ONE':
      return {
        query: gql`
          query promotion($id: ID) {
            promotion(id: $id) {
              ${promotion}
            }
          }
        `,
        variables: params, // params = { id: ... }
        parseResponse: response => ({
          data: outputTransformer(response.data.promotion)
        })
      }
      break
    case 'GET_LIST':
      const queryParams = promotionQueryParams(params)
      return {
        query: gql`
          query {
            promotions (
              page: ${queryParams.page},
              perPage: ${queryParams.perPage},
              search: ${queryParams.search},
              status: ${queryParams.status},
              sort: ${queryParams.sort}
            ) {
              promotions {
                ${promotion}
              }
              dataView {
                pagination {
                  recordCount
                }
              }
            }
          }
        `,
        variables: {},
        parseResponse: response => ({
          data: response.data.promotions.promotions,
          total: response.data.promotions.dataView.pagination.recordCount
        })
      }
      break
    case 'UPDATE':
      return {
        query: gql`
          mutation UpsertPromotion($input: UpsertPromotionInput) {
            upsertPromotion(input: $input) {
              promotion {
                ${promotion}
              }
            }
          }
        `,
        variables: {
          input: sanitizeParams(params.data, upsertPromotionPermittedParams)
        },
        parseResponse: response => ({
          data: outputTransformer(response.data.upsertPromotion.promotion),
          total: 1
        })
      }
      break
    case 'CREATE':
      delete params.data.__typename
      return {
        query: gql`
          mutation UpsertPromotion($input: UpsertPromotionInput) {
            upsertPromotion(input: $input) {
              promotion {
                ${promotion}
              }
            }
          }
        `,
        variables: {
          input: sanitizeParams(params.data, upsertPromotionPermittedParams)
        },
        parseResponse: response => ({
          data: outputTransformer(response.data.upsertPromotion.promotion),
          total: 1
        })
      }
      break
  }
}
