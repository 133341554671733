import React, { Fragment } from 'react'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import { TextInput } from 'react-admin'
import { withStyles } from '@material-ui/core'
import { url } from 'redux-form-validators'

const style = {
  details: {
    display: 'block'
  }
}

export const ResidentPortalInput = withStyles(style)(({ classes }) => (
  <Fragment>
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        Resident Portal
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <TextInput
          label="Sub Text"
          source="config.resident_portal.sub_text"
          options={{ fullWidth: true }}
        />
        <TextInput
          label="Button Link"
          source="config.resident_portal.button_link"
          validate={url({ allowBlank: true })}
          options={{ fullWidth: true }}
        />
        <TextInput
          label="Button Text"
          source="config.resident_portal.button_text"
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </Fragment>
))
