import { BaseProcessor } from './baseProcessor'

const endpoint = 'divisions'

const fields = {
  property_id: { required: true, key: 'id' },
  sub_text: { required: false, key: 'config.resident_portal.sub_text' },
  button_link: { required: true, key: 'config.resident_portal.button_link' },
  button_text: { required: true, key: 'config.resident_portal.button_text' }
}

export default new BaseProcessor(endpoint, fields)
