import { BaseProcessor } from './baseProcessor'

const endpoint = 'divisions'

const fields = {
  parent_id: { required: true },
  property_name: { required: true, key: 'name' },
  number_of_units: { required: true },
  street: { required: false, key: 'address.street' },
  city: { required: false, key: 'address.city' },
  state: { required: false, key: 'address.state' },
  zip: { required: false, key: 'address.zip', dynamicTyping: false },
  logo_id: { required: false },
  website: { required: false },
  facebook_url: { required: false },
  twitter_url: { required: false },
  first_name: { required: false },
  last_name: { required: false },
  email: { required: false },
  external_ids: { required: true, value: externalIds },
  phone: { required: false },
  status: { required: false }
}

function externalIds(value) {
  if (!value) {
    return
  }
  return value.toString().split(',')
}

export default new BaseProcessor(endpoint, fields)
