import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-admin'
import { bulkProcess } from '../../actions/bulkProcess'

class SendNowInvitesButton extends Component {
  handleClick = () => {
    const { basePath, selectedIds, bulkProcess } = this.props
    bulkProcess(
      basePath,
      { data: selectedIds.map(id => ({ id, send_now: true })) },
      false
    )
  }

  render() {
    return <Button label="Send Now" onClick={this.handleClick} />
  }
}

export default connect(undefined, { bulkProcess })(SendNowInvitesButton)
