import { inviteCancel } from '../../actions/invite'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

class CancelInviteButton extends Component {
  handleClick = () => {
    const { inviteCancel, record, basePath } = this.props
    inviteCancel(record, basePath)
  }

  hidden = () => {
    const { record } = this.props
    return record !== undefined ? record.status === 'cancelled' : true
  }

  render() {
    if (!this.hidden()) {
      return (
        <Button color="primary" onClick={this.handleClick}>
          Cancel Invite
        </Button>
      )
    } else {
      return <span />
    }
  }
}

CancelInviteButton.propTypes = {
  inviteCancel: PropTypes.func.isRequired,
  record: PropTypes.object
}

export default connect(null, {
  inviteCancel
})(CancelInviteButton)
