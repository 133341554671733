import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { css, styles } from './iframeStyles'
import { graphQLHeaders } from '../../utils/upd-data-provider/dataProvider'
import { useMountedRef } from '../../utils/useMountedRef'
import { useScript } from '../../utils/useScript'

const graphQLUrl = `${process?.env?.UPD_API_URL}/graphql`
const accessToken = () => sessionStorage.getItem('access-token')

const query = `
  mutation GenerateTokenexIframeDetokenizationConfig($input: TokenexDetokeningIframeInput!) {
    config: generateTokenexIframeDetokenizationConfig(input: $input) {
      timestamp
      tokenExID
      authenticationKey
    }
  }
`

const scriptConfig = () => {
  return location.origin.includes('staging') ||
    location.origin.includes('localhost')
    ? {
        src: 'https://test-htp.tokenex.com/Iframe/iframe-v3.41.min.js',
        integrity:
          'sha384-eePjvZ5S0b9RUZx7RkGNcH8blvSQdw09HkWDyhPrMyfJDD/4lWjRRJ0HCSKI0gqr',
        crossOrigin: 'anonymous'
      }
    : {
        src: 'https://htp.tokenex.com/Iframe/Iframe-v3.41.min.js',
        integrity:
          'sha384-dJY8nUeyNGP4iXGrTCsZMIFQub7oULC94WxlotldgWAEmWGeF8tNArSwl873YF6G',
        crossOrigin: 'anonymous'
      }
}

/**
 * @typedef {{ scheme: 'PCI' token: string, mode?: 'PAN' | 'CVV' }} Props
 */

// TODO: Come back to this
/**
 * @typedef {{ token: string, mode?: 'PAN' | 'CVV' | 'SSN' | 'ASCIITOKEN' }} IFrameConfigProps
 */

let instanceId = 0
/**
 * @param {Props} props
 */
export function TokenexDetokenizer({
  scheme,
  token,
  /**
   * https://docs.tokenex.com/docs/iframe-modes
   */
  mode
}) {
  const scriptStatus = useScript(scriptConfig(), { removeOnUnmount: true })
  // TODO: Remove it? scheme isn't being used
  const config = useTokenexIframeConfig({ scheme, token, mode })
  const el = useRef()
  const id = useMemo(() => `tokenex-${instanceId++}`, [])
  const mounted = useMountedRef()

  const [statusText, setStatusText] = useState('loading')

  useEffect(() => {
    if (scriptStatus === 'ready' && el && config?.authenticationKey) {
      if (mounted.current) {
        setStatusText('')
      }

      const iframe = new window.TokenEx.DetokenizeIframe(id, {
        ...config,
        styles
      })
      iframe.on('expired', () => setStatusText('Expired, please refresh page'))
      iframe.load()

      return () => iframe?.remove()
    }
  }, [scriptStatus, config, el, id])

  return (
    <>
      {statusText && (
        <b>
          &nbsp;
          {statusText}
        </b>
      )}
      <span className={css.iframe} ref={el} id={id} />
    </>
  )
}

/**
 * @param {IFrameConfigProps} props
 */
function useTokenexIframeConfig({
  scheme,
  token,
  /**
   * https://docs.tokenex.com/docs/iframe-modes
   */
  mode
}) {
  const [tokenExIFrameResults, setTokenExIFrameResults] = useState()
  const mounted = useMountedRef()
  const origin = location.origin
  const body = JSON.stringify({
    query,
    variables: {
      input: {
        origin,
        vault: 'TOKENEX_TVI_VAULT',
        token
      }
    }
  })

  const fetchToken = useCallback(async () => {
    try {
      const { data } = await (
        await fetch(graphQLUrl, {
          method: 'POST',
          headers: {
            ...graphQLHeaders(),
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken()}`
          },
          body
        })
      ).json()

      return data?.config
    } catch (error) {
      return error
    }
  }, [graphQLUrl])

  useEffect(() => {
    fetchToken()
      .then(results => {
        if (mounted.current)
          setTokenExIFrameResults({
            ...results,
            tokenScheme: scheme,
            origin,
            token,
            pci: scheme === 'PCI',
            expiresInSeconds: 300,
            ...(mode === 'CVV' && {
              cvv: true,
              cvvOnly: true
            })
          })
      })
      .catch(e => console.error(e))
  }, [fetchToken])

  return useMemo(
    () =>
      !tokenExIFrameResults || tokenExIFrameResults instanceof Error
        ? undefined
        : tokenExIFrameResults,
    [tokenExIFrameResults]
  )
}
