import React, { Component } from 'react'
import {
  List,
  Datagrid,
  DeleteWithConfirmButton,
  TextField,
  EmailField
} from 'react-admin'

class AdminList extends Component {
  render() {
    return (
      <List {...this.props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
          <TextField source="id" label="ID" />
          <TextField source="first_name" label="First Name" />
          <TextField source="last_name" label="Last Name" />
          <EmailField source="email" label="Email" />
          <TextField source="proper_email" label="Proper Email" />
          <TextField source="roles[0].name" label="Role" />
          <DeleteWithConfirmButton />
        </Datagrid>
      </List>
    )
  }
}

export default AdminList
