import {
  TopToolbar,
  Datagrid,
  Edit,
  Filter,
  List,
  SimpleForm,
  TextField,
  TextInput,
  SelectInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  DateInput,
  Create,
  ReferenceInput,
  AutocompleteInput,
  required
} from 'react-admin'
import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core'
import CancelInviteButton from './buttons/inviteCancel'
import CancelInvitesButton from './buttons/inviteCancelMany'
import SendNowInvitesButton from './buttons/inviteSendNowMany'
import { LinkResourceField, StateSelectInput } from './fields'
import Pluralize from 'pluralize'
import { get } from 'lodash'
import { UpdaterCloneButton } from './buttons/updaterCloneButton'

const inputStyle = {
  input_container: {
    'margin-top': '1em',
    padding: '1em',
    'box-shadow': '0px 1px 3px 0px rgba(0, 0, 0, 0.2)'
  },
  input_title: {
    color: 'rgba(0, 0, 0, 0.87)',
    'margin-bottom': '1em'
  }
}

const InviteFilter = props => (
  <Filter {...props}>
    <TextInput label="Email" source="mover_email" defaultValue="" />
    <TextInput label="ID" source="id" defaultValue="" />
    <TextInput label="Company Id" source="company_id" defaultValue="" />
    <SelectInput
      label="Status"
      source="status"
      choices={[
        { id: 'not_sent', name: 'Not Sent' },
        { id: 'sent_to_mover', name: 'Sent to Mover' },
        { id: 'opened', name: 'Opened' },
        { id: 'answered', name: 'answered' },
        { id: 'claimed', name: 'Claimed' },
        { id: 'resent_to_mover', name: 'Resent to Mover' },
        { id: 'cancelled', name: 'Cancelled' },
        { id: 'bounced', name: 'Bounced' },
        { id: 'rejected', name: 'Rejected' }
      ]}
    />
  </Filter>
)

const InviteBulkActionButtons = props => (
  <Fragment>
    <CancelInvitesButton {...props} />
    <SendNowInvitesButton {...props} />
  </Fragment>
)

export const InviteList = props => (
  <List
    {...props}
    bulkActionButtons={<InviteBulkActionButtons />}
    filters={<InviteFilter />}
  >
    <Datagrid {...props} rowClick="edit">
      <TextField source="id" label="Id" />
      <TextField source="token" label="Token" />
      <LinkResourceField
        label="Company"
        source="company.id"
        reference={record =>
          `${Pluralize.plural(get(record, 'company.category', 'company'))}`
        }
        display="company.name"
        sortable={false}
      />
      <TextField source="status" label="Status" />
      <TextField source="first_name" label="First Name" />
      <TextField source="last_name" label="Last Name" />
      <TextField source="email" label="Email" />
      <TextField source="estimated_move_date" label="Est. Move Date" />
      <TextField source="send_date" label="Send Date" />
      <TextField source="created_at" label="Created At" />
      <TextField source="updated_at" label="Updated At" />
      <UpdaterCloneButton
        omitFields={['company_id', 'company', 'agent_id', 'professional']}
      />
    </Datagrid>
  </List>
)

const InviteEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)
const InviteInput = withStyles(inputStyle)(({ classes }) => (
  <Fragment>
    <TextInput source="email" label="Email" options={{ fullWidth: true }} />
    <TextInput
      source="first_name"
      label="First Name"
      options={{ fullWidth: true }}
    />
    <TextInput
      source="last_name"
      label="Last Name"
      options={{ fullWidth: true }}
    />
    <TextInput
      source="cell_phone"
      label="Cell Phone"
      options={{ fullWidth: true }}
    />
    <TextInput
      source="home_phone"
      label="Home Phone"
      options={{ fullWidth: true }}
    />
    <div className={classes.input_container}>
      <div className={classes.input_title}>From Address</div>
      <TextInput
        label="Street"
        source="from_address.street"
        options={{ fullWidth: true }}
        resettable
      />
      <TextInput label="Apt" source="from_address.apt" resettable />
      <TextInput label="City" source="from_address.city" resettable />
      <StateSelectInput
        label="State"
        source="from_address.state"
        allowEmpty={true}
      />
      <TextInput label="Zip" source="from_address.zip" resettable />
    </div>
    <div className={classes.input_container}>
      <div className={classes.input_title}>To Address</div>
      <TextInput
        label="Street"
        source="to_address.street"
        options={{ fullWidth: true }}
        resettable
      />
      <TextInput label="Apt" source="to_address.apt" resettable />
      <TextInput label="City" source="to_address.city" resettable />
      <StateSelectInput
        label="State"
        source="to_address.state"
        allowEmpty={true}
      />
      <TextInput label="Zip" source="to_address.zip" resettable />
    </div>
    <SelectInput
      allowEmpty
      source="direction"
      label="Direction (only for PM)"
      choices={[
        { id: 'move_in', name: 'Move In' },
        { id: 'move_out', name: 'Move Out' },
        { id: 'transfer', name: 'Transfer' }
      ]}
    />
    <div className={classes.input_container}>
      <div className={classes.input_title}>Professional</div>
      <TextInput
        label="First Name"
        source="professional.first_name"
        options={{ fullWidth: true }}
      />
      <TextInput
        label="Last Name"
        source="professional.last_name"
        options={{ fullWidth: true }}
      />
      <TextInput
        label="Email"
        source="professional.email"
        options={{ fullWidth: true }}
      />
      <TextInput
        label="External ID"
        source="professional.external_id"
        options={{ fullWidth: true }}
      />
    </div>
    <SelectInput
      source="gender"
      label="Gender"
      choices={[
        { id: 'male', name: 'Male' },
        { id: 'female', name: 'Female' }
      ]}
    />
    <TextInput multiline label="Message" source="message" />
    <DateInput
      label="Estimated Move Date"
      source="estimated_move_date"
      validate={required()}
    />
    <div>
      <TextInput
        multiline
        label="Metadata"
        source="metadata"
        parse={v => JSON.parse(v)}
        format={v => JSON.stringify(v)}
      />
    </div>
    <BooleanInput label="Send Now" source="send_now" />
    <DateInput label="Send Date" source="send_date" />
    {/* we want this to be hidden for now, so it's not overused */}
    <BooleanInput style={{ display: 'none' }} label="Test" source="test" />
  </Fragment>
))

const InviteEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <CancelInviteButton record={data} />
    <UpdaterCloneButton
      record={data}
      omitFields={['company_id', 'company', 'agent_id', 'professional']}
      basePath={basePath}
    />
  </TopToolbar>
)

const validateInviteEdit = values => {
  if (
    values.from_address &&
    !values.from_address.street &&
    !values.from_address.city &&
    !values.from_address.state &&
    !values.from_address.zip
  ) {
    values.from_address_id = null
  }
  if (
    values.to_address &&
    !values.to_address.street &&
    !values.to_address.city &&
    !values.to_address.state &&
    !values.to_address.zip
  ) {
    values.to_address_id = null
  }
  return {} // errors
}

export const InviteEdit = props => (
  <Edit title="Edit Invite" {...props} actions={<InviteEditActions />}>
    <SimpleForm toolbar={<InviteEditToolbar />} validate={validateInviteEdit}>
      <TextInput disabled label="Id" source="id" />
      <TextInput disabled label="Company/Property Id" source="company.id" />
      <TextInput disabled label="Professional Id" source="professional.id" />
      <InviteInput />
    </SimpleForm>
  </Edit>
)

function optionText(choice) {
  if (!choice) {
    return ''
  }

  return `${choice.id} - ${choice.name}`
}

export const InviteCreate = props => (
  <Create title="Create Invite" {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Company/Property"
        source="company_id"
        reference="entities"
        filterToQuery={searchText => ({ full_text: searchText })}
        filter={{ category: ['company', 'division'] }}
        sort={{ field: 'name', order: 'ASC' }}
        validate={required()}
      >
        <AutocompleteInput
          source="name"
          limitChoicesToValue={true}
          optionText={optionText}
        />
      </ReferenceInput>
      <ReferenceInput
        label="Professional"
        source="agent_id"
        reference="professionals"
        filterToQuery={searchText => ({ full_text: searchText })}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput
          source="name"
          limitChoicesToValue={true}
          optionText={optionText}
        />
      </ReferenceInput>
      <InviteInput />
    </SimpleForm>
  </Create>
)
