import {
  Datagrid,
  Create,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
  SelectInput,
  BooleanInput,
  ReferenceInput,
  ReferenceField,
  AutocompleteInput,
  Filter,
  required
} from 'react-admin'
import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core'

// TODO: Does this functionality exist in React-Admin? Could not find it
const FormattedTextField = ({ formattedText, record = {} }) => (
  <span>{formattedText(record)}</span>
)

const inputStyle = {
  input_container: {
    'margin-top': '1em',
    padding: '1em',
    'box-shadow': '0px 1px 3px 0px rgba(0, 0, 0, 0.2)'
  },
  input_title: {
    color: 'rgba(0, 0, 0, 0.87)',
    'margin-bottom': '1em'
  }
}

const kindChoices = [
  {
    id: 'include',
    name: 'include'
  },
  {
    id: 'exclude',
    name: 'exclude'
  }
]

const EntityItemDefFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Entity"
      source="entityId"
      reference="entities"
      filterToQuery={searchText => ({ full_text: searchText })}
      sort={{ field: 'name', order: 'ASC' }}
      alwaysOn
    >
      <AutocompleteInput
        source="name"
        limitChoicesToValue={true}
        optionText={choice => (choice ? `${choice.id} - ${choice.name}` : '')}
        required
        fullWidth
      />
    </ReferenceInput>
  </Filter>
)

export const EntityItemDefinitionsList = props => (
  <List {...props} filters={<EntityItemDefFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="Id" />
      <TextField source="kind" label="Kind" />
      <ReferenceField
        label="Entity"
        source="entityId"
        reference="entities"
        linkType={false}
      >
        <FormattedTextField
          formattedText={choice => `${choice.id} - ${choice.name}`}
          required
          fullWidth
        />
      </ReferenceField>
      <ReferenceField
        label="Item Definition"
        source="itemDefinitionId"
        reference="item_definitions"
        linkType={false}
      >
        <FormattedTextField
          formattedText={({ id, code, short_description }) =>
            `${id}: ${short_description} (${code})`
          }
          required
          fullWidth
        />
      </ReferenceField>
      <TextField source="enabled" label="Enabled" />
      <TextField source="reason" label="Reason" />
    </Datagrid>
  </List>
)

const EntityItemDefinitionDetailInput = withStyles(inputStyle)(() => (
  <Fragment>
    <SelectInput required source="kind" label="Kind" choices={kindChoices} />
    <BooleanInput source="enabled" label="Enabled" />
    <TextInput required source="reason" label="Reason" fullWidth />
  </Fragment>
))

export const CreateEntityItemDefinition = props => (
  <Create title="Create Entity Item Definition" {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Entity"
        source="entityId"
        reference="entities"
        filterToQuery={searchText => ({ full_text: searchText })}
        sort={{ field: 'name', order: 'ASC' }}
        validate={required()}
      >
        <AutocompleteInput
          source="name"
          limitChoicesToValue={true}
          optionText={choice => (choice ? `${choice.id} - ${choice.name}` : '')}
          required
          fullWidth
        />
      </ReferenceInput>
      <ReferenceInput
        label="Item Definition"
        source="itemDefinitionId"
        reference="item_definitions"
      >
        <SelectInput
          source="id"
          optionText={({ id, code, short_description }) =>
            `${id}: ${short_description} (${code})`
          }
          required
          fullWidth
        />
      </ReferenceInput>
      <EntityItemDefinitionDetailInput />
    </SimpleForm>
  </Create>
)

export const EditEntityItemDefinition = props => (
  <Edit title="Edit Entity Item Definition" {...props}>
    <SimpleForm>
      <TextField source="id" label="Id" />
      <ReferenceField
        label="Entity"
        source="entityId"
        reference="entities"
        linkType={false}
      >
        <FormattedTextField
          formattedText={choice => `${choice.id} - ${choice.name}`}
          required
          fullWidth
        />
      </ReferenceField>
      <ReferenceField
        label="Item Definition"
        source="itemDefinitionId"
        reference="item_definitions"
        linkType={false}
      >
        <FormattedTextField
          formattedText={({ id, code, short_description }) =>
            `${id}: ${short_description} (${code})`
          }
          required
          fullWidth
        />
      </ReferenceField>
      <EntityItemDefinitionDetailInput />
    </SimpleForm>
  </Edit>
)
