import React, { Fragment } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import { FormDataConsumer, TextInput } from 'react-admin'
import { withStyles } from '@material-ui/core'

const style = {
  details: {
    display: 'block'
  }
}

export const VaultInput = withStyles(style)(({ classes }) => (
  <Fragment>
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        Vault
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData) {
              switch (formData.integration_id) {
                case 3:
                  break
                case 440:
                case 441:
                  break
                case 305:
                  return (
                    <Fragment>
                      <TextInput
                        label="email"
                        source="vault_params.email"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                      <TextInput
                        label="password"
                        source="vault_params.password"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                    </Fragment>
                  )
                  break
                case 325:
                case 2000:
                case 2157:
                case 2158:
                  return (
                    <Fragment>
                      <TextInput
                        label="username"
                        source="vault_params.username"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                      <TextInput
                        label="password"
                        source="vault_params.password"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                    </Fragment>
                  )
                  break
                case 2001:
                case 2154:
                case 2166:
                case 371:
                case 2153:
                case 2165:
                  return (
                    <Fragment>
                      <TextInput
                        label="username"
                        source="vault_params.username"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                      <TextInput
                        label="password"
                        source="vault_params.password"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                    </Fragment>
                  )
                  break
                case 2006:
                case 2007:
                  return (
                    <Fragment>
                      <TextInput
                        label="username"
                        source="vault_params.user"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                      <TextInput
                        label="password"
                        source="vault_params.password"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                      <TextInput
                        label="static_proxy"
                        source="vault_params.static_proxy"
                        options={{ fullWidth: true }}
                        defaultValue="http://quotaguard4627:74e72a9ed8c3@us-east-1-static-hopper.quotaguard.com:9293"
                        {...rest}
                      />
                    </Fragment>
                  )
                  break
                case 2010:
                  return (
                    <Fragment>
                      <TextInput
                        label="username"
                        source="vault_params.username"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                      <TextInput
                        label="password"
                        source="vault_params.password"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                    </Fragment>
                  )
                  break
                case 2013:
                  break
                case 2025:
                  break
                case 2042:
                  break
              }
            }
          }}
        </FormDataConsumer>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </Fragment>
))
