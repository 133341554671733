import React, { Fragment } from 'react'
import {
  Edit,
  BooleanInput,
  BooleanField,
  Create,
  CloneButton,
  List,
  Datagrid,
  DateField,
  TextField,
  Filter,
  TextInput,
  AutocompleteInput,
  SelectInput,
  ReferenceInput,
  SimpleForm,
  required,
  email
} from 'react-admin'

export const ProfessionalFilter = props => (
  <Filter {...props}>
    <TextInput label="First Name" source="first_name" defaultValue="" />
    <TextInput label="id" source="id" defaultValue="" />
  </Filter>
)

export const ProfessionalList = props => (
  <List {...props} filters={<ProfessionalFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <TextField source="external_id" label="External ID" />
      <TextField source="first_name" label="First Name" />
      <TextField source="last_name" label="Last Name" />
      <TextField source="status" label="Status" />
      <DateField source="cancelled_date" />
      <BooleanField
        source="enable_automated_invites"
        label="Enable Automated Invites"
      />
      <BooleanField
        source="notify_before_invite"
        label="Notify Before Invite"
      />
      <TextField source="phone" label="Phone" />
      <TextField source="website" label="Website" />
      <TextField source="facebook_url" label="Facebook URL" />
      <TextField source="twitter_url" label="Twitter URL" />
      <CloneButton />
    </Datagrid>
  </List>
)

const ProfessionalInput = () => (
  <Fragment>
    <TextInput source="external_id" label="External ID" />
    <TextInput
      label="First Name"
      source="first_name"
      options={{ fullWidth: true }}
      validate={required()}
    />
    <TextInput
      label="Last Name"
      source="last_name"
      options={{ fullWidth: true }}
      validate={required()}
    />
    <TextInput
      label="Email"
      source="email"
      validate={email()}
      options={{ fullWidth: true }}
    />
    <TextInput label="Phone" source="phone" options={{ fullWidth: true }} />
    <SelectInput
      label="Status"
      source="status"
      choices={[
        { id: 'new', name: 'New' },
        { id: 'live', name: 'Live' },
        { id: 'paused', name: 'Paused' },
        { id: 'cancelled', name: 'Cancelled' }
      ]}
      options={{ fullWidth: true }}
    />
    <SelectInput
      label="Subscription Type"
      source="subscription_type"
      choices={[
        { id: 'premium', name: 'Premium' },
        { id: 'standard', name: 'Standard' }
      ]}
    />
    <BooleanInput
      label="Enable Automated Invites "
      source="enable_automated_invites"
    />
    <BooleanInput label="Notify Before Invite" source="notify_before_invite" />
    <TextInput label="Website" source="website" options={{ fullWidth: true }} />
    <TextInput
      label="Facebook"
      source="facebook_url"
      options={{ fullWidth: true }}
    />
    <TextInput
      label="Twitter"
      source="twitter_url"
      options={{ fullWidth: true }}
    />
  </Fragment>
)

export const ProfessionalEdit = props => (
  <Edit title="Edit Professional" {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <ProfessionalInput />
    </SimpleForm>
  </Edit>
)

export const ProfessionalCreate = props => (
  <Create title="Create Professional" {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Parent"
        source="parent_id"
        reference="companies"
        filterToQuery={searchText => ({ full_text: searchText })}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput source="name" optionText="name" />
      </ReferenceInput>
      <ProfessionalInput />
    </SimpleForm>
  </Create>
)
