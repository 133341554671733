import { FormDataConsumer, NumberInput, SelectInput } from 'react-admin'
import React, { Fragment } from 'react'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import { withStyles } from '@material-ui/core'

const style = {
  details: {
    display: 'block'
  }
}

const Integrations = [
  {id: 'entrata', name: 'Entrata'},
  {id: 'realpage', name: 'RealPage'},
  {id: 'resman', name: 'Resman'},
  {id: 'yardi', name: 'Yardi'}
]

function getIntegration(config = {}) {
  return Object.keys(config['writeback_entity_configurations'] || {})[0]
}

export const WritebackEntityConfigurationsInput = withStyles(style)(
  ({ classes }) => (
    <Fragment>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          Writeback Entity Configurations
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return(
                <SelectInput
                  source="integration"
                  choices={Integrations}
                  allowEmpty
                  resettable
                  initialValue={getIntegration(formData.config)}
                  {...rest}
                />
              )
          }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (formData.integration) {
                return (
                  <NumberInput
                    label="Entity Configuration ID"
                    source={`config.writeback_entity_configurations.${formData.integration}`}
                    {...rest}
                  />
                )
              }
            }}
          </FormDataConsumer>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Fragment>
  )
)
