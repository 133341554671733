export function hasPermission(
  permissions = [],
  requiredPermission,
  requiredActions
) {
  if (!requiredActions || requiredActions.length === 0) {
    requiredActions = ['read']
  }
  for (const role of requiredActions.map(action => `${action}:${requiredPermission}`)) {
    if (permissions.indexOf(role) >= 0) return true
  }
  return false
}

export const getSubscribedHomeServicesOrderStatuses = permissions => {
  const action2Status = {
    'processing:home_services_order': 'ready',
    'resolving:home_services_order': 'pending'
  }

  return permissions
    .filter(action => action in action2Status)
    .map(action => action2Status[action])
}

export const allPermissions = ['read:company',
  'create:company',
  'update:company',
  'read:division',
  'create:division',
  'update:division',
  'read:invite',
  'create:invite',
  'update:invite',
  'read:user',
  'create:user',
  'update:user',
  'read:admin',
  'create:admin',
  'update:admin',
  'read:professional',
  'create:professional',
  'update:professional',
  'read:promotion',
  'create:promotion',
  'update:promotion',
  'read:utility_provider',
  'create:utility_provider',
  'update:utility_provider',
  'read:entity_item_definition',
  'create:entity_item_definition',
  'update:entity_item_definition',
  'read:home_services_order',
  'update:home_services_order',
  'processing:home_services_order',
  'resolving:home_services_order',
  'read:entity',
  'create:entity',
  'update:entity',
  'read:forward_mail',
  'update:forward_mail',
]