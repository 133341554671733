import React from 'react'
import classes from './addressField.module.css'

const AddressField = ({ label, source, record = {}, showLabel = true }) => {
  const address = record[source]
  if (address) {
    return (
      <div className="MuiFormControl-root MuiFormControl-marginDense">
        {label && showLabel && (
          <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">
            {label}
          </label>
        )}
        <div
          className={`${
            label && showLabel ? classes.address : ''
          } MuiTypography-root MuiTypography-body1`}
        >
          <div>
            {address.street} {address.apt}
          </div>
          <div>
            {address.city}, {address.state}, {address.zip}
          </div>
        </div>
      </div>
    )
  }
  return ''
}

export default AddressField
