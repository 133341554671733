import React, { Fragment } from 'react'

import { BooleanInput } from 'react-admin'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import { FEATURES } from '../constants/features'
import { withStyles } from '@material-ui/core'

const style = {
  details: {
    display: 'block'
  },
  tips: {
    color: 'red',
    'font-size': '0.8em',
    'margin-bottom': '0.5em'
  }
}

export const FeaturesInput = withStyles(style)(({ classes }) => (
  <Fragment>
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        Features
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        {FEATURES.map(feature => (
          <BooleanInput
            key={feature.key}
            label={feature.label}
            source={`config.features.${feature.key}`}
          />
        ))}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </Fragment>
))
