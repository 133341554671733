import React from 'react'
import { Datagrid, DatagridBody, useRedirect } from 'react-admin'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import classes from './fullServiceMoveGrid.module.css'

const FullServiceMoveGridRow = props => {
  const {
    record,
    resource,
    id,
    onToggleItem,
    children,
    selected,
    basePath,
    className,
    rowClick
  } = props
  const redirect = useRedirect()
  return (
    <TableRow key={id} className={className}>
      <TableCell padding="none">
        <div className={classes.checkboxArea}>
          {!record || record.move_id ? (
            <span className="MuiButtonBase-root MuiIconButton-root PrivateSwitchBase-root-283 MuiCheckbox-root MuiCheckbox-colorSecondary PrivateSwitchBase-disabled-285 Mui-disabled MuiIconButton-colorSecondary Mui-disabled Mui-disabled">
              <span className="MuiIconButton-label">
                <svg className="MuiSvgIcon-root">
                  <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
                </svg>
              </span>
            </span>
          ) : (
            <Checkbox checked={selected} onClick={() => onToggleItem(id)} />
          )}
        </div>
      </TableCell>
      {React.Children.map(children, field => (
        <TableCell
          key={`${id}-${field.props.source}`}
          onClick={() => {
            if (rowClick === 'edit') redirect(`${basePath}/${id}`)
          }}
          style={{ width: field.props.width }}
        >
          {React.cloneElement(field, {
            record,
            basePath,
            resource
          })}
        </TableCell>
      ))}
    </TableRow>
  )
}

const FullServiceMoveGridBody = props => (
  <DatagridBody {...props} row={<FullServiceMoveGridRow />} />
)
const FullServiceMoveGrid = props => (
  <Datagrid
    {...props}
    isRowSelectable={record => record && !record.move_id}
    body={<FullServiceMoveGridBody />}
  />
)

export default FullServiceMoveGrid
