import { BaseProcessor } from './baseProcessor'
import { STEPS } from '../../components/constants/steps'
import { url } from 'redux-form-validators'

const endpoint = 'divisions'

const fields = {
  property_id: { required: true, key: 'id' },
  step: {
    required: true,
    excluded: true,
    validate: value => {
      if (!STEPS.includes(value.toLowerCase()))
        return `must be one of ${STEPS.join(', ')}`
      else return undefined
    }
  },
  banner_text: {
    required: true,
    key: row => `config.banner_html.${row['step'].toLowerCase()}`,
    value: bannerHtml
  },
  link_text: { required: false, excluded: true },
  url: {
    required: false,
    excluded: true,
    validate: url()
  },
  ending_text: { required: false, excluded: true }
}

function bannerHtml(value, row) {
  let html = ''
  if (row['link_text'] && row['url']) {
    html = `${row['banner_text']} <a href="${row['url']}">${
      row['link_text']
    }</a>${
      row['ending_text'] === null || row['ending_text'] === undefined
        ? ''
        : ` ${row['ending_text']}`
    }`
  } else {
    html = row['banner_text']
  }
  return `<strong>${html}</strong>`
}

export default new BaseProcessor(endpoint, fields)
