import { updJsonApiDataProvider } from './upd-data-provider/dataProvider'
import {
  GET_LIST
} from 'react-admin'
import { jwtDecode } from 'jwt-decode'
import { allPermissions } from './permission'
import Cookies from 'universal-cookie'


/**
 * Get a session value or set it if it doesn't exist
 * @param key{string}
 * @param generator{function(): Promise<T>}
 * @return {Promise<T>}
 * @template T
 */
async function sessionGetOrSet(key, generator) {
  const value = sessionStorage.getItem(key)
  if (value)
    return JSON.parse(value)

  const newValue = await generator()
  if (!newValue) {
    sessionStorage.removeItem(key)
  } else {
    sessionStorage.setItem(key, JSON.stringify(newValue))
  }
  return newValue
}


class AuthProvider {
  async login({ accessToken }) {
    sessionStorage.setItem('access-token', accessToken)
    sessionStorage.removeItem('access-permissions')
  }

  async logout(...args) {
    // react-admin calls logout() during page load
    // but we only want to actually force a logout if the user
    // clicks the logout button.
    if (await this.checkAuth({ nothrow: true })) {
      sessionStorage.clear()
      setTimeout(() => {
        window.location = '/auth-worker/logout.html'
      }, 1)
    }
  }

  async checkAuth({ nothrow }) {
    const newToken = new Cookies(document.cookie).get('auth0.token')
    const existingToken = sessionStorage.getItem('access-token')
    if (!existingToken || newToken != existingToken) {
      if (nothrow) {
        return false
      }
      throw new Error('Not authenticated')
    }
    return true
  }

  async checkError({ status, message }) {
    if (
      status === 401 ||
      status === 403 ||
      // check for graphql calls since we don't get status from Apollo.
      message.indexOf('Received status code 401') >= 0
    ) {
      //await this.logout()
    }
  }

  async getPermissions() {
    const accessToken = sessionStorage.getItem('access-token')
    if (!accessToken) return []

    return await sessionGetOrSet('access-permissions', async () => {
      let rawPermissions = await updJsonApiDataProvider()(GET_LIST, 'permissions')
      const roles = []
      for (const { id, actions } of rawPermissions) {
        for (const action of actions) {
          roles.push(`${action}:${id}`)
        }
      }
      return roles
    })
  }

  async getIdentity() {
    const accessToken = sessionStorage.getItem('access-token')
    const claims = jwtDecode(accessToken)
    return {
      ...claims,
      id: claims.sub
    }
  }
}

export default new AuthProvider()