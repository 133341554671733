import gql from 'graphql-tag'
import { sanitizeParams } from './helpers'

const upsertUtilityProviderPostalCodePermittedParams = [
  'id',
  'externalId',
  'source',
  'postalCode',
  'state',
  'predominant',
  'appended'
]

const utilityPostalCode = `
  id 
  externalId 
  source 
  postalCode 
  state 
  predominant 
  appended
`
const utilityPostalCodeUpsert = data => ({
  query: gql`
      mutation UpsertUtilityProviderPostalCode($input: UpsertUtilityProviderPostalCodeInput!) {
        upsertUtilityProviderPostalCode(input: $input) {
          utilityPostalCode {
            ${utilityPostalCode}
          }
        }
      }
    `,
  variables: {
    input: sanitizeParams(data, upsertUtilityProviderPostalCodePermittedParams)
  },
  parseResponse: response => ({
    data: response.data.upsertUtilityProviderPostalCode.utilityPostalCode,
    total: 1
  })
})
const utilityPostalCodeDelete = data => ({
  query: gql`
      mutation {
        deleteUtilityProviderPostalCode(id: ${data.id}) {
          id
        }
      }
    `,
  variables: {
    input: sanitizeParams(data, upsertUtilityProviderPostalCodePermittedParams)
  },
  parseResponse: response => ({
    data: response.data.deleteUtilityProviderPostalCode,
    total: 1
  })
})
export default function(raFetchType, params) {
  const externalId =
    params.filter && params.filter.externalId ? params.filter.externalId : ``
  const postalCode =
    params.filter && params.filter.postalCode ? params.filter.postalCode : ``

  const isFilterInputValid = () => externalId.length + postalCode.length > 4

  switch (raFetchType) {
    case 'GET_ONE':
      return {
        query: gql`
          query {
            searchUtilityProviderPostalCodes(
                id: "${params.id}"
            ) {
              ${utilityPostalCode}
            }
          }
        `,
        variables: {},
        parseResponse: response => ({
          data: response.data.searchUtilityProviderPostalCodes[0],
          total: 1
        })
      }
      break
    case 'GET_LIST':
      const searchQuery = isFilterInputValid()
        ? gql`
          query {
            searchUtilityProviderPostalCodes(
              externalId: "${externalId}",
              postalCode: "${postalCode}"
            ) {
              ${utilityPostalCode}
            }
          }
        `
        : null
      return {
        query: searchQuery,
        variables: {},
        parseResponse: response => ({
          data: response.data.searchUtilityProviderPostalCodes,
          total: 1
        })
      }
      break
    case 'UPDATE':
      delete params.data.__typename
      return utilityPostalCodeUpsert(params.data)
      break
    case 'CREATE':
      delete params.data.__typename
      params.data.source = 'EDWIN'
      params.data.appended = true
      return utilityPostalCodeUpsert(params.data)
      break
    case 'DELETE':
      return utilityPostalCodeDelete(params.previousData)
  }
}
