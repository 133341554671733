import React from 'react'
import { useDispatch } from 'react-redux'
import {
  useNotify,
  useRedirect,
  useRefresh,
  fetchStart,
  fetchEnd
} from 'react-admin'
import { moveCancel } from '../../actions/fullServiceMove'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/EventBusy'
import classes from './fullServiceMoveCancel.module.css'

export default function FullServiceMoveCancelButton({
  record,
  basePath,
  className,
  noLabel = false
}) {
  const dispatch = useDispatch()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const notify = useNotify()
  function handleClick(e) {
    e.stopPropagation()
    dispatch(fetchStart())
    moveCancel(record, basePath)
      .then(({ json }) => ({ data: json }))
      .then(() => {
        redirect('/relocation/appointments')
        refresh()
        notify('Move Successfully Cancelled')
      })
      .catch(() => {
        notify('Move could NOT be cancelled.', 'warning')
      })
      .finally(() => {
        dispatch(fetchEnd()) // stop the global loading indicator
      })
  }

  function hidden() {
    return record !== undefined ? !(record.move_id && record.booked) : true
  }

  return !hidden() ? (
    <Button
      color="primary"
      onClick={handleClick}
      className={`${className} ${classes.button}`}
    >
      <CancelIcon style={{ marginRight: '5px' }} />
      {noLabel ? '' : 'Cancel Appointment'}
    </Button>
  ) : (
    <span />
  )
}
