import React, { Fragment } from 'react'
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core'

const ServiceAddress = ({ address }) => (
  <Fragment>
    <b>Service Address</b>
    <div>Zip: {address.zip}</div>
    <div>Street: {address.street}</div>
    <div>Apartment: {address.apt}</div>
    <div>City: {address.city}</div>
    <div>State: {address.state}</div>
  </Fragment>
)

export default function ContactInfo({
  record: {
    order_source,
    contact_information = {},
    move: { to_address = {} } = {}
  } = {}
}) {
  const addressInfo =
    (Object.keys(to_address).length === 0
      ? contact_information.to_address
      : to_address) || {}
  return (
    <Fragment>
      <Card style={{ marginTop: 20 }}>
        <CardHeader title="User Info" />
        <CardContent>
          <Grid container direction="row" justifyContent="center">
            <Grid item xs={6}>
              <ServiceAddress address={addressInfo} />
            </Grid>
            <Grid item xs={6}>
              <div>
                Name: {contact_information.first_name}{' '}
                {contact_information.last_name}
              </div>
              <div>Email: {contact_information.email}</div>
              <div>Phone: {contact_information.phone || 'N/A'}</div>
              <div>Source: {order_source}</div>
              {contact_information.uuid && (
                <div> UUID: {contact_information.uuid} </div>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  )
}
