import buildGraphQLProvider from 'ra-data-graphql'
import { createHttpLink } from 'apollo-link-http'
import { fetchUtils } from 'react-admin'
import jsonAPIDataProvider from './jsonAPIDataProvider'
import buildQuery from './graphqlDataProvider'
import configServiceDataProvider from './configServiceDataProvider'
import addLogoUploadFeature from './logoUploadFeature'

const { UPD_API_URL, UPD_CONFIG_SERVICE_URL } = process.env
export const apiUrl = UPD_API_URL

const jsonAPIUrl = `${apiUrl}/v2`
const graphQLUrl = `${apiUrl}/graphql`

const accessToken = () => sessionStorage.getItem('access-token')

const setHeaders = (headers = {}) => {
  headers['Authorization'] = `Bearer ${accessToken()}`
  headers.app = 'jarvis'
  return headers
}

export const graphQLHeaders = () => {
  const headers = {}
  setHeaders(headers)
  return headers
}

const graphqlLink = () =>
  new createHttpLink({
    uri: graphQLUrl,
    headers: graphQLHeaders()
  })

const jsonAPIHeaders = () => {
  const headers = new Headers(setHeaders())
  headers.set('Content-Type', 'application/json')
  headers.set('Accept', 'application/vnd.api+json')
  return headers
}

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = jsonAPIHeaders()
  }
  return fetchUtils.fetchJson(url, options).catch(e => ({ status: 200, headers: {}, body: '[]', json: [] }))
}

async function buildUPDGraphQLDataProvider() {
  return await buildGraphQLProvider({
    buildQuery,
    clientOptions: { link: graphqlLink() }
  })
}

const updJsonApiDataProvider = () =>
  addLogoUploadFeature(jsonAPIDataProvider(jsonAPIUrl, httpClient))

const configServiceHttpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  options.headers.set('Content-Type', 'application/json')
  options.headers.set('Authorization', `Bearer ${accessToken()}`)
  return fetchUtils.fetchJson(url, options)
}

const updConfigServiceDataProvider = () =>
  configServiceDataProvider(UPD_CONFIG_SERVICE_URL, configServiceHttpClient)

export {
  buildUPDGraphQLDataProvider,
  updJsonApiDataProvider,
  updConfigServiceDataProvider,
  httpClient,
  jsonAPIUrl
}
