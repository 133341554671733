import React from 'react'
import SunIcon from '@material-ui/icons/Brightness5'
import MoonIcon from '@material-ui/icons/Brightness3'

const WindowField = ({ source, record = {} }) => {
  const value = record[source]
  if (value === 'am') {
    return <SunIcon style={{ color: '#fbbe0c' }} />
  }
  if (value === 'pm') {
    return <MoonIcon style={{ color: '#5181dc' }} />
  }
  return <>{value}</>
}

export default WindowField
