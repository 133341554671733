import {
  Datagrid,
  Create,
  Edit,
  Filter,
  List,
  SimpleForm,
  TextField,
  TextInput,
  SelectInput,
  required
} from 'react-admin'
import React, { Fragment, useMemo } from 'react'
import { withStyles } from '@material-ui/core'
import StateSelectInput from './fields/stateSelectInput'

const inputStyle = {
  input_container: {
    'margin-top': '1em',
    padding: '1em',
    'box-shadow': '0px 1px 3px 0px rgba(0, 0, 0, 0.2)'
  },
  input_title: {
    color: 'rgba(0, 0, 0, 0.87)',
    'margin-bottom': '1em'
  }
}

const utilityTypes = [
  { _id: 'GAS', name: 'Gas' },
  { _id: 'ELECTRIC', name: 'Electric' },
  { _id: 'WATER', name: 'Water' }
]

// Fairly reliable UUID generator based on current timstamp & time since page load
// Source: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
const generateUUID = () => {
  // Public Domain/MIT
  let d = new Date().getTime() //Timestamp
  let d2 = (performance && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = Math.random() * 16 //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

const UtilityKindSelectInput = props => (
  <SelectInput
    label={props.label || 'Kind'}
    source={props.source || 'kind'}
    choices={utilityTypes}
    optionText="name"
    optionValue="_id"
    allowEmpty={false}
    validate={required()}
  />
)

const UtilityFilter = props => (
  <Filter {...props}>
    <TextInput label="Keyword" source="keyword" />
    <UtilityKindSelectInput alwaysOn />
    <StateSelectInput alwaysOn source="addressState" validate={required()} />
  </Filter>
)

export const UtilitiesList = props => (
  <List
    {...props}
    filters={<UtilityFilter />}
    filterDefaultValues={{ kind: 'GAS', addressState: 'NY' }}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" label="Id" />
      <TextField source="name" label="Name" />
      <TextField source="kind" label="kind" />
      <TextField source="localPhone" label="Local Phone" />
      <TextField source="businessPhone" label="Business Phone" />
      <TextField source="email" label="Email" />
      <TextField source="website" label="website" />
      <TextField source="externalId" label="External Id" />
    </Datagrid>
  </List>
)

const UtilityDetailInput = withStyles(inputStyle)(() => {
  const externalId = useMemo(() => generateUUID(), [])
  return (
    <Fragment>
      <UtilityKindSelectInput />
      <TextInput source="name" label="Name" validate={required()} fullWidth />
      <TextInput source="localPhone" label="Local Phone" fullWidth />
      <TextInput source="businessPhone" label="Business Phone" fullWidth />
      <TextInput source="email" label="Email" fullWidth />
      <TextInput source="website" label="Website" fullWidth />
      <StateSelectInput
        source="state"
        label="State"
        validate={required()}
        allowEmpty={false}
        fullwidth
      />
      <TextInput
        source="externalId"
        label="External Id"
        defaultValue={externalId}
        fullWidth
        helperText={`This is a unique id which is used
        to link this utility to the ZIP codes it services.
        You can change this id if desired,
        otherwise we have generated one for you.
        `}
      />
    </Fragment>
  )
})

export const CreateUtility = props => (
  <Create title="Create Utility" {...props}>
    <SimpleForm>
      <UtilityDetailInput />
    </SimpleForm>
  </Create>
)

export const EditUtility = props => (
  <Edit title="Edit Utility" {...props}>
    <SimpleForm>
      <UtilityDetailInput />
      <TextField source="source" label="Source" disabled />
    </SimpleForm>
  </Edit>
)
