import React from 'react'
import { withStyles, Modal, Button } from '@material-ui/core'

const styles = {
  paper: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: 400,
    padding: '1em',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
    backgroundColor: 'white',
    outline: 0
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    minWidth: '12px',
    minHeight: '12px'
  }
}

export default withStyles(styles)(({ classes, children, title, ...props }) => (
  <Modal {...props}>
    <div className={classes.paper}>
      <Button
        component="span"
        className={classes.close}
        onClick={props.onClose}
      >
        X
      </Button>
      <h2>{title}</h2>
      {children}
    </div>
  </Modal>
))
