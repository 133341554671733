/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {
  Title,
  SimpleForm,
  TextInput,
  required,
  minLength,
  regex,
  UPDATE
} from 'react-admin'
import {
  updJsonApiDataProvider,
  apiUrl
} from '../utils/upd-data-provider/dataProvider'

const SetPassword = withRouter(
  ({
    match: {
      params: { token }
    },
    history
  }) => {
    const [valid, setValidity] = useState(null)
    const [error, setError] = useState(null)
    useEffect(() => {
      fetch(`${apiUrl}/v2/password_resets/${token}`).then(response => {
        setValidity(response.status === 200)
      })
    }, [token])

    return (
      <Card>
        <Title title="Set Password" />
        <CardContent
          style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}
        >
          {valid !== null && !valid ? (
            <div>
              Sorry, this token is no longer valid.{' '}
              <Link to="/login">Sign in to continue.</Link>
            </div>
          ) : (
            <div>Please set a new password.</div>
          )}
          <SimpleForm
            validate={({ password, passwordConfirmation }) => {
              if (password !== passwordConfirmation) {
                return { passwordConfirmation: 'Passwords should match.' }
              }

              return {}
            }}
            save={({ password }) => {
              setError(null)

              if (valid) {
                updJsonApiDataProvider()(UPDATE, 'users', {
                  id: '',
                  data: {
                    password,
                    security_token: token
                  }
                }).then(
                  () =>
                    history.push({
                      pathname: '/login',
                      state: {
                        message:
                          'Successfully updated your password. Sign in to continue.'
                      }
                    }),
                  () => {
                    setError('Something went wrong.')
                  }
                )
              }
            }}
          >
            <TextInput
              label="Password"
              source="password"
              type="password"
              validate={[
                required(),
                minLength(8, 'Password must be at least 8 characters.'),
                regex(/\d{1}/, 'Password must have at least one number.'),
                regex(/[a-zA-Z]{1}/, 'Password must have at least one letter.')
              ]}
            />
            <TextInput
              label="Password Confirmation"
              source="passwordConfirmation"
              type="password"
              validate={required()}
            />
          </SimpleForm>
          {error ? <div>{error}</div> : ''}
        </CardContent>
      </Card>
    )
  }
)

export default SetPassword
