const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

const paths = []
const logos = []

const findLogos = (obj, path = '') => {
  if ((obj.logo || {}).rawFile) {
    logos.push(obj.logo)
    paths.push(path)
  }
  Object.keys(obj).forEach(key => {
    if (obj[key] instanceof Object) {
      const newPath = path ? `${path}.${key}` : path + key
      findLogos(obj[key], newPath)
    }
  })
}

const addLogoUploadFeature = requestHandler => (type, resource, params) => {
  if (type === 'CREATE' || type === 'UPDATE') {
    findLogos(params.data)
    if (logos.length > 0) {
      return Promise.all(logos.map(logo => convertFileToBase64(logo.rawFile)))
        .then(base64Logos => {
          base64Logos.map((logo64, index) => {
            const path = paths[index]
            if (path) {
              let obj = params.data
              const keys = path.split('.')
              keys.forEach((key, index) => {
                obj = obj[key]
                if (index === keys.length - 1) {
                  obj.logo = logo64
                }
              })
            } else {
              params.data.logo = logo64
            }
          })
        })
        .then(() => requestHandler(type, resource, params))
    }
  }

  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params)
}

export default addLogoUploadFeature
