import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio
} from '@material-ui/core'
import { parseJson } from '../../../../components/formatters/parseJson'
import React, { Fragment, useState } from 'react'
import { CreditCard } from '../../../creditCard'
import { TokenexASCIIDisplay } from '../../../tokenexASCIIDisplay'
import { TokenexSSNDisplay } from '../../../tokenexSSNDisplay'
import Activity from '../activity'
import Modal from '../modal'
import PackageInfo from '../packageInfo'
import UserInfo from '../userInfo'

const securityQuestionDictionary = {
  firstPet: 'What is the name of your first pet?',
  thirdGradeCity: 'What city did you lived on in 3rd grade?',
  firstGradeTeacher: 'What was the name of your first grade teacher?',
  cityParentsMet: 'What is the name of the city that your parents met?',
  grandmotherMaidenName: "What is your maternal grandmother's maiden name?",
  school: 'What school did you attend when you were 10 years old?',
  firstConcert: 'What was the first concert that you attended?'
}

/* Equipment */
const Equipment = ({ classes, record }) => (
  <Fragment>
    <Card style={{ marginTop: 20 }}>
      <h3>Choose your equipment</h3>
      <CardContent>
        {record.installation.internet_modem_choice === 'internetRental' && (
          <div>
            <FormControlLabel
              control={
                <Radio
                  checked={
                    record.installation.internet_modem_choice ===
                    'internetRental'
                  }
                  classes={{ disabled: classes.disabled }}
                />
              }
              label={`${record.price.items['internet_rental'].label} (${record.price.items['internet_rental'].price_description})`}
              labelPlacement="end"
              classes={{ label: classes.label }}
              disabled
            />
          </div>
        )}
        <div>
          <FormControlLabel
            control={
              <Radio
                checked={
                  record.installation.internet_modem_choice ===
                  'internetModemOwned'
                }
                classes={{ disabled: classes.disabled }}
              />
            }
            label="I'll use my own"
            labelPlacement="end"
            classes={{ label: classes.label }}
            disabled
          />
        </div>
      </CardContent>
      <h3>Streaming so simple: Contour stream player</h3>
      <CardContent>
        <FormControlLabel
          control={
            <Checkbox checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="0"
          labelPlacement="end"
          classes={{ label: classes.label }}
          disabled
        />
      </CardContent>
    </Card>
  </Fragment>
)

/* Credit Check-SSN-Contact */
const CreditCheckInfo = ({
  classes,
  serviceAddress,
  ssnToken,
  currentAddressIsOverSixMonths,
  priorAddress
}) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <Fragment>
      <h3>Credit Check</h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="I authorize a credit check"
          labelPlacement="end"
          classes={{ label: classes.label }}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <h3>SSN</h3>
      </div>
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" onClick={handleOpen}>
          Show Full SSN Info
        </Button>
        <Modal open={open} onClose={handleClose} title="Full SSN Info">
          <div>
            <TokenexSSNDisplay token={ssnToken} />
          </div>
        </Modal>
      </div>
      <h3>Have you lived for 6 months or longer at the address below?</h3>
      <div>
        <Address address={serviceAddress} />
      </div>
      <div>
        <FormControlLabel
          control={<Radio checked={currentAddressIsOverSixMonths} />}
          label="Yes"
          labelPlacement="end"
          classes={{ label: classes.label }}
        />
      </div>
      <div>
        <FormControlLabel
          control={<Radio checked={!currentAddressIsOverSixMonths} />}
          label="No"
          labelPlacement="end"
          classes={{ label: classes.label }}
        />
      </div>
      {!currentAddressIsOverSixMonths && (
        <Fragment>
          <div>
            <h3>Prior Address</h3>
            <div>
              <b>Street:</b> {priorAddress?.address?.street}
            </div>
            <div>
              <b>City:</b> {priorAddress?.address?.city}
            </div>
            <div>
              <b>State:</b> {priorAddress?.address?.state}
            </div>
            <div>
              <b>Zip Code:</b>{' '}
              {priorAddress?.address?.postalCode ||
                priorAddress?.address?.postal_code}
            </div>
          </div>
        </Fragment>
      )}
      <p>Verification - Credit Results / Credit Options</p>
      <p>
        Contact consumer if they fail the credit results and will be required to
        pay a $50.00 deposit to go forward.
      </p>
    </Fragment>
  )
}

/* PIN-Security-DOB */
const Security = ({ record, dobToken, passcodeToken, securityAnswerToken }) => {
  const [openDobModal, setOpenDobModal] = useState(false)
  const handleOpenDobModal = () => setOpenDobModal(true)
  const handleCloseDobModal = () => setOpenDobModal(false)
  const [openSecurityAnswerModal, setOpenSecurityAnswerModal] = useState(false)
  const handleOpenSecurityAnswerModal = () => setOpenSecurityAnswerModal(true)
  const handleCloseSecurityAnswerModal = () => setOpenSecurityAnswerModal(false)
  const [openPasscodeModal, setOpenPasscodeModal] = useState(false)
  const handleOpenPasscodeModal = () => setOpenPasscodeModal(true)
  const handleClosePasscodeModal = () => setOpenPasscodeModal(false)
  return (
    <Fragment>
      <Card style={{ marginTop: 20 }}>
        <CardContent>
          <h3>Create a Cox Pin</h3>
          <div style={{ marginTop: 20 }}>
            <Button variant="contained" onClick={handleOpenPasscodeModal}>
              Show Cox Pin
            </Button>
            <Modal
              open={openPasscodeModal}
              onClose={handleClosePasscodeModal}
              title="Passcode"
            >
              <div>
                <TokenexASCIIDisplay token={passcodeToken} />
              </div>
            </Modal>
          </div>

          <h3>Show pin on bill?</h3>
          <div>
            <p>Yes</p>
          </div>

          <h3>Security Question</h3>
          <div>
            <p>
              {
                securityQuestionDictionary[
                  record?.credit_check?.security_questions?.security_question
                ]
              }
            </p>
          </div>

          <h3>Security Answer</h3>
          <div style={{ marginTop: 20 }}>
            <Button variant="contained" onClick={handleOpenSecurityAnswerModal}>
              Show Security Answer
            </Button>
            <Modal
              open={openSecurityAnswerModal}
              onClose={handleCloseSecurityAnswerModal}
              title="Security Answer"
            >
              <div>
                <TokenexASCIIDisplay token={securityAnswerToken} />
              </div>
            </Modal>
          </div>

          <h3>Date of Birth</h3>
          <div style={{ marginTop: 20 }}>
            <Button variant="contained" onClick={handleOpenDobModal}>
              Show Date of Birth
            </Button>
            <Modal
              open={openDobModal}
              onClose={handleCloseDobModal}
              title="Date of Birth"
            >
              <div>
                <TokenexASCIIDisplay token={dobToken} />
              </div>
            </Modal>
          </div>
        </CardContent>
      </Card>
    </Fragment>
  )
}

/* Installation */
const Installation = ({ classes }) => (
  <Fragment>
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <h3>Installation Options</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Self Install - (Shipment) if not available defer to Self - Activation"
            labelPlacement="end"
            disabled
            classes={{ label: classes.label }}
          />
        </div>
      </CardContent>
    </Card>
  </Fragment>
)
/* Additional Questions */
const OtherQuestions = ({ classes }) => (
  <Fragment>
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <h3>Emergency Broadband Relief</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="No"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>

        <h3>Internet Terms and Conditions</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>

        <h3>Terms and Conditions of Service</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>

        <h3>1 Year Term Agreement</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>

        <h3>Easy Pay Agreement</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>

        <h3>Monthly Total Agreement</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>
      </CardContent>
    </Card>
  </Fragment>
)

const BillingInfo = ({ record }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const {
    billing_address: billingAddress = {},
    expiration_date: expirationDate,
    first_name: firstName,
    last_name: lastName,
    pci = {}
  } = record?.payment?.credit_card || {}
  const creditCardProps = {
    ...pci,
    expirationDate,
    firstName,
    lastName
  }
  return (
    <Fragment>
      <div>
        <h3>Credit Card Info</h3>
        <div>
          <Button variant="contained" onClick={handleOpen}>
            Show Credit Card Info
          </Button>
          <Modal open={open} onClose={handleClose} title="Credit Card Info">
            <CreditCard
              {...creditCardProps}
              allowTokenexDetokenizer={true}
              allowTokenDisplay={true}
            />
          </Modal>
        </div>
      </div>
      <div>
        <h3>Billing Address</h3>
        <div>
          <b>Street:</b> {billingAddress?.street}
        </div>
        <div>
          <b>Apartment:</b> {billingAddress?.unit}
        </div>
        <div>
          <b>City:</b> {billingAddress?.city}
        </div>
        <div>
          <b>State:</b> {billingAddress?.state}
        </div>
        <div>
          <b>Zip Code:</b>{' '}
          {billingAddress?.postalCode || billingAddress?.postal_code}
        </div>
      </div>
    </Fragment>
  )
}

const AdditionalOptions = ({ classes, record: { opt_ins = {} } = {} }) => (
  <Fragment>
    <h3>
      Allow Updater and Cox to reach you by phone or text if there’s a problem
      with my order?
    </h3>
    <div>
      <FormControlLabel
        control={
          <Radio
            checked={opt_ins.sms_opt_in}
            classes={{ disabled: classes.disabled }}
          />
        }
        label="Yes, I consent"
        labelPlacement="end"
        disabled
        classes={{ label: classes.label }}
      />
    </div>
  </Fragment>
)

const Address = ({ address }) => (
  <Fragment>
    <div>
      {address?.street} {Boolean(address?.apt) && ` Apt. ${address?.apt}`}
    </div>

    <div>
      {address?.city}, {address?.state} {address?.zip}
    </div>
  </Fragment>
)

/* Main constructed component */
export const OrderView = ({ record, classes }) => {
  const currentAddressIsOverSixMonths = Boolean(
    record.credit_check?.has_lived_at_current_address_for_more_than_6_months ==
      'Yes'
  )
  const priorAddress = record?.credit_check?.prior_address
  const ssnToken = parseJson(record?.credit_check?.ssn?.token)?.token
  const dobToken = parseJson(record?.credit_check?.date_of_birth?.token)?.token
  const securityAnswerToken = parseJson(
    record?.credit_check?.security_questions?.security_answer?.token
  )?.token
  const passcodeToken = parseJson(record?.credit_check?.passcode?.token)?.token
  return (
    <Fragment>
      <Grid item xs={6}>
        <UserInfo record={record} />
      </Grid>
      <Grid item xs={6}>
        <PackageInfo record={record} />
      </Grid>

      <Grid item xs={12}>
        <Equipment classes={classes} record={record} />
      </Grid>

      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardContent>
            <CreditCheckInfo
              record={record}
              classes={classes}
              serviceAddress={record.move.to_address}
              ssnToken={ssnToken}
              currentAddressIsOverSixMonths={currentAddressIsOverSixMonths}
              priorAddress={priorAddress}
              passcodeToken={passcodeToken}
              securityAnswerToken={securityAnswerToken}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Billing info" />
          <CardContent>
            <BillingInfo record={record} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Security
          record={record}
          dobToken={dobToken}
          passcodeToken={passcodeToken}
          securityAnswerToken={securityAnswerToken}
        />
      </Grid>
      <Grid item xs={12}>
        <Installation classes={classes} />
      </Grid>
      <Grid item xs={12}>
        <AdditionalOptions classes={classes} record={record} />
      </Grid>
      <Grid item xs={12}>
        <OtherQuestions classes={classes} />
      </Grid>
      <Grid item xs={12}>
        <Activity record={record} classes={classes} />
      </Grid>
    </Fragment>
  )
}
