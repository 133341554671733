import {
  promotionsProvider,
  utilitiesProvider,
  entityItemDefinitionsProvider,
  utilityPostalCodesProvider
} from './graphql-providers'

const introspectionResults = () => (raFetchType, resourceName, params) => {
  switch (resourceName) {
    case 'promotions':
      return promotionsProvider(raFetchType, params)
    case 'utilities':
      return utilitiesProvider(raFetchType, params)
    case 'entityItemDefinitions':
      return entityItemDefinitionsProvider(raFetchType, params)
    case 'utilityPostalCodes':
      return utilityPostalCodesProvider(raFetchType, params)
  }
}

export default introspectionResults
