import { TextInput, ImageInput, ImageField } from 'react-admin'
import React, { Fragment } from 'react'
import { url } from 'redux-form-validators'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import { withStyles } from '@material-ui/core'

const style = {
  details: {
    display: 'block'
  }
}

const cloudinaryUrl = process.env.UPD_CLOUDINARY_URL

export const LogoUploadInput = withStyles(style)(
  ({ classes, record, sourcePrefix = '' }) => (
    <Fragment>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          Logo
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <img
            hidden={!record.logo_id}
            src={`${cloudinaryUrl}${record.logo_id}.jpg`}
          />
          <TextInput
            label="New Logo - Via Url"
            source={sourcePrefix ? `${sourcePrefix}.logo_url` : 'logo_url'}
            options={{ fullWidth: true }}
            validate={url({ allowBlank: true })}
          />
          <ImageInput
            label="New Logo - Via File"
            placeholder={
              <p>Drop of click here to upload new logo, or use url above.</p>
            }
            accept="image/*"
            multiple={false}
            source={sourcePrefix ? `${sourcePrefix}.logo` : 'logo'}
          >
            <ImageField
              source={sourcePrefix ? `${sourcePrefix}.logo` : 'logo'}
            />
          </ImageInput>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Fragment>
  )
)
