export const FEATURES = [
  { key: 'cobranded_agents', label: 'Co-branded Agents', defaultValue: true },
  {
    key: 'home_services_providers',
    label: 'Home Services Providers',
    defaultValue: false
  },
  {
    key: 'agent_preferred_providers',
    label: 'Agent Preferred Providers',
    defaultValue: false
  },
  {
    key: 'preferred_providers',
    label: 'Preferred Providers (Local Guides)',
    defaultValue: false
  }
]
