import {
  Button,
  Card,
  CardContent,
  Checkbox,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import {
  ConfigureResidentPortalProcessor,
  ConfigureUtilitiesProcessor,
  ConfigureBannerProcessor,
  CreateManagerProcessor,
  UploadPortfolioProcessor,
  ConfigureRentersInsuranceProcessor
} from '../utils/bulk-processors'
import React, { Component } from 'react'

import Dropzone from 'react-dropzone'
// TODO: ra3
// https://github.com/marmelab/react-admin/blob/next/UPGRADE.md#custom-exporter-functions-must-use-jsonexport-instead-of-papaparse
import Papa from 'papaparse'
import { bulkProcess } from '../actions/bulkProcess'
import { connect } from 'react-redux'
import { showNotification } from 'react-admin'

// TODO: ties premissions with process type
export const permissions = ['user', 'company']

// TODO: ties premissions with process type
export const actions = ['read', 'update']

const bulkProcessTypes = {
  'Create Managers': CreateManagerProcessor,
  'Upload Portfolio': UploadPortfolioProcessor,
  'Configure Resident Portal': ConfigureResidentPortalProcessor,
  'Configure Banners': ConfigureBannerProcessor,
  'Configure Utilities': ConfigureUtilitiesProcessor,
  'Configure Renters Insurance': ConfigureRentersInsuranceProcessor
}

let fileReader = []

class BulkImport extends Component {
  constructor() {
    super()
    const [selectedProcessType] = Object.keys(bulkProcessTypes)
    this.state = {
      selectedProcessType,
      selectedProcessClass: bulkProcessTypes[selectedProcessType],
      tableElements: []
    }
  }

  changeSelectedProcessType = event => {
    this.setState({
      selectedProcessType: event.target.value,
      selectedProcessClass: bulkProcessTypes[event.target.value]
    })
  }

  handleFile = file => {
    fileReader = new FileReader()
    fileReader.onloadend = this.parseCsv
    fileReader.readAsText(file)
  }

  parseCsv = () => {
    Papa.parse(fileReader.result, {
      header: true,
      dynamicTyping: field =>
        this.state.selectedProcessClass.dynamicTypingCheck(field),
      skipEmptyLines: true,
      beforeFirstChunk: chunk => this.stripWhitespaceFromHeaders(chunk),
      complete: results => {
        try {
          const data = this.state.selectedProcessClass.validatedData(
            results.data
          )
          this.setState({ tableElements: data })
        } catch (e) {
          this.props.showNotification(e.toString(), 'error')
        }
      }
    })
  }

  stripWhitespaceFromHeaders = chunk => {
    const rows = chunk.split(/\r\n|\r|\n/)
    const headings = rows[0].replace(/\s+/g, '')
    rows[0] = headings
    return rows.join('\r\n')
  }

  processRows = () => {
    const data = this.state.tableElements.filter(
      obj => !obj['validation_errors']
    )
    this.props.bulkProcess(
      this.state.selectedProcessClass.endpoint,
      { data: this.state.selectedProcessClass.transformedData(data) },
      true,
      this.renderRequestResults
    )
  }

  renderRequestResults = payload => {
    const failedArray = payload.failed || payload.meta.failed
    const refreshedTableElements = this.state.tableElements.map(obj => {
      const failed = failedArray.find(
        failed_obj => failed_obj.params.meta.row_id === obj.meta.row_id
      )
      if (failed) {
        obj.error = failed.reason
      } else {
        obj.error = null
        obj.meta.success = true
      }
      return obj
    })

    this.setState({ tableElements: refreshedTableElements })
  }

  render() {
    let metaIndex
    return (
      <Card style={{ padding: '1em' }}>
        <CardContent>Bulk Processing</CardContent>
        <Select
          value={this.state.selectedProcessType}
          onChange={this.changeSelectedProcessType}
        >
          {Object.keys(bulkProcessTypes).map(type => (
            <MenuItem key={type} value={type}>
              <em>{type}</em>
            </MenuItem>
          ))}
        </Select>
        <Dropzone accept=".csv" onDrop={files => this.handleFile(files[0])}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps()}
              style={{
                width: '100%',
                height: '200px',
                margin: '1em auto',
                display: 'flex',
                borderWidth: '2px',
                borderColor: '#ccc',
                borderStyle: 'dashed',
                borderRadius: '5px',
                backgroundColor: '#f5f5f5',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop files here...</p>
              ) : (
                <p>
                  Try dropping some files here, or click to select files to
                  upload.
                </p>
              )}
            </div>
          )}
        </Dropzone>
        <div hidden={this.state.tableElements.length === 0}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={this.processRows}
          >
            Process All Valid Rows
          </Button>
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(this.state.tableElements[0] || {}).map(
                  (col, colIndex) => {
                    if (col === 'meta') {
                      metaIndex = colIndex
                      return
                    }
                    return <TableCell key={colIndex}>{col}</TableCell>
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.tableElements.map((row, rowIndex) => {
                let style = { backgroundColor: 'white' }
                if (row.validation_errors) {
                  style = { backgroundColor: '#F4CBA0' } //orange
                } else if (row.error) {
                  style = { backgroundColor: '#F4A8A0' } //red
                } else if (row.meta.success) {
                  style = { backgroundColor: '#BCF4A0' } //green
                }
                return (
                  <TableRow key={rowIndex}>
                    {Object.values(row).map((col, colIndex) => {
                      if (colIndex === metaIndex) {
                        return
                      }
                      return (
                        <TableCell
                          style={style}
                          key={`${rowIndex}-${colIndex}`}
                        >
                          {typeof col === 'boolean' ? (
                            <Checkbox checked={col} disabled={true} />
                          ) : (
                            col
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </Card>
    )
  }
}

export default connect(null, {
  bulkProcess,
  showNotification
})(BulkImport)
