import {
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  minLength,
  maxLength,
  regex,
  required,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin'
import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core'
import StateSelectInput from './fields/stateSelectInput'

const inputStyle = {
  input_container: {
    'margin-top': '1em',
    padding: '1em',
    'box-shadow': '0px 1px 3px 0px rgba(0, 0, 0, 0.2)'
  }
}

const UtilityPostalCodeFilter = props => (
  <Filter {...props}>
    <TextInput label="Postal Code" alwaysOn source="postalCode" />
    <TextInput label="Utility External Id" alwaysOn source="externalId" />
  </Filter>
)
export const UtilityPostalCodesList = props => (
  <List
    {...props}
    filters={<UtilityPostalCodeFilter />}
    filterDefaultValues={{ postalCode: '90210' }}
    title="Utility Postal Codes"
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" label="Id" sortable={false} />
      <TextField source="externalId" label="external Id" sortable={false} />
      <TextField source="postalCode" label="postal code" sortable={false} />
      <TextField source="state" label="state" sortable={false} />
      <TextField source="source" label="source" sortable={false} />
      <TextField source="appended" label="appended" sortable={false} />
    </Datagrid>
  </List>
)

const UtilityPostalCodeDetailInput = withStyles(inputStyle)(() => (
  <Fragment>
    <BooleanInput
      source="predominant"
      label="Predominant - Make this the main utility for servicing this postal code."
    />
    <BooleanInput source="appended" label="Appended" />
  </Fragment>
))

export const CreateUtilityPostalCode = props => (
  <Create title="Create Utility Postal Code" {...props}>
    <SimpleForm>
      <TextInput
        label="Utility External Id"
        alwaysOn
        source="externalId"
        validate={required()}
        helperText={`This is a unique id which is used
        to link a utility company to this postal code.`}
        fullWidth
      />
      <TextInput
        source="postalCode"
        label="Postal Code"
        validate={[
          required(),
          minLength(5, 'Must be a 5 digit postal code'),
          maxLength(5, 'Must be a 5 digit postal code'),
          regex(/^\d+$/, 'Must be a 5 digit postal code')
        ]}
      />
      <StateSelectInput
        source="state"
        label="State"
        validate={required()}
        allowEmpty={false}
      />
      <UtilityPostalCodeDetailInput />
    </SimpleForm>
  </Create>
)

export const EditUtilityPostalCode = props => (
  <Edit title="Edit Utility Postal Code" {...props}>
    <SimpleForm>
      <TextInput label="Utility External Id" source="externalId" disabled />
      <TextInput source="postalCode" label="Postal Code" disabled />
      <TextInput source="state" label="State" disabled />
      <UtilityPostalCodeDetailInput />
      <TextField source="source" label="Source" disabled />
    </SimpleForm>
  </Edit>
)
