import React, { useState } from 'react'
import { TextField, MenuItem, Select } from '@material-ui/core'
import { useInput } from 'react-admin'

const DateRangeFilter = props => {
  const [dateFrom, setDateFrom] = useState('')
  const [operation, setOperation] = useState('eq')
  const {
    input: { onChange }
  } = useInput(props)

  function processQuery({ newOperation, newDate }) {
    const currentDate = newDate || dateFrom
    const currentOperation = newOperation || operation
    if (currentDate && currentOperation) {
      let query = currentDate
      if (currentOperation === 'lt') {
        query = { '<': currentDate }
      }
      if (currentOperation === 'gt') {
        query = { '>': currentDate }
      }
      onChange(query)
    }
  }
  function onChangeOperation(e) {
    e.stopPropagation()
    e.preventDefault()
    setOperation(e.target.value)
    processQuery({ newOperation: e.target.value })
  }
  function onChangeFrom(e) {
    e.stopPropagation()
    e.preventDefault()
    setDateFrom(e.target.value)
    processQuery({ newDate: e.target.value })
  }
  return (
    <>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={operation}
        onChange={onChangeOperation}
        style={{ marginRight: '10px' }}
      >
        <MenuItem value="eq">Equal</MenuItem>
        <MenuItem value="gt">After</MenuItem>
        <MenuItem value="lt">Before</MenuItem>
      </Select>
      <TextField
        label="From"
        type="date"
        InputLabelProps={{ shrink: true }}
        onChange={onChangeFrom}
      />
    </>
  )
}

export default DateRangeFilter
