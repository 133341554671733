import {
  TabbedForm,
  FormTab,
  ArrayInput,
  AutocompleteInput,
  TopToolbar,
  Datagrid,
  BooleanInput,
  Create,
  Edit,
  FormDataConsumer,
  List,
  NumberInput,
  ReferenceInput,
  required,
  SimpleFormIterator,
  TextField,
  TextInput,
  SelectInput,
  CheckboxGroupInput,
  AutocompleteArrayInput,
  Filter
} from 'react-admin'
import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core'
import { sanitizer } from './formatters/sanitizer'
import LinkResourceField from './fields/linkResourceField'
import Pluralize from 'pluralize'
import { get } from 'lodash'
import { url } from 'redux-form-validators'
import { UpdaterCloneButton } from './buttons/updaterCloneButton'

const objectifyOptions = opts => opts.map(e => ({ id: e, name: e }))

const inputStyle = {
  input_container: {
    'margin-top': '1em',
    padding: '1em',
    'box-shadow': '0px 1px 3px 0px rgba(0, 0, 0, 0.2)'
  },
  input_title: {
    color: 'rgba(0, 0, 0, 0.87)',
    'margin-bottom': '1em'
  }
}

export const PromotionsList = props => (
  <List
    filters={<PromotionFilter />}
    filterDefaultValues={{ status: ['live'] }}
    {...props}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" label="Id" />
      <TextField source="code" label="Code" />
      <TextField source="name" label="Name" />
      <TextField
        source="metadata.displayName"
        label="Display Name"
        sortable={false}
      />
      <TextField source="status" label="Status" />
      <TextField source="metadata.order" label="Order" sortable={false} />
      <LinkResourceField
        label="For Entity"
        source="forEntity.id"
        reference={record => {
          let type = get(record, 'forEntity.__typename', 'company')
          type = type === 'Location' ? 'division' : type
          return `${Pluralize.plural(type)}`
        }}
        display="forEntity.name"
        sortable={false}
      />
      <TextField source="placements" label="Placements" sortable={false} />
      <UpdaterCloneButton omitFields={['externalIds']} />
    </Datagrid>
  </List>
)

const PromotionFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="search" />
    <AutocompleteArrayInput
      label="Status"
      source="status"
      choices={objectifyOptions(['draft', 'live', 'paused', 'expired'])}
    />
  </Filter>
)

const PromotionSettingsInput = ({ record }) => (
  <Fragment>
    <TextInput
      source="code"
      label="Code"
      validate={required('A unique code must be assigned to promotion.')}
    />
    <br />
    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <TextInput
          source="externalId"
          label="ExternalId"
          initialValue={formData.externalIds && formData.externalIds[0]}
          {...rest}
        />
      )}
    </FormDataConsumer>
    <TextInput source="name" label="Name" fullWidth />
    <SelectInput
      source="status"
      label="Status"
      choices={objectifyOptions(['draft', 'live', 'paused', 'expired'])}
      fullWidth
    />
    <SelectInput
      source="metadata.claimType"
      label="Claim Type"
      choices={objectifyOptions(['physical', 'digital'])}
      fullWidth
    />
    <NumberInput source="metadata.order" label="Order" />
    <img
      hidden={record.image ? !record.image.url : true}
      src={record.image ? record.image.url : null}
    />
    <TextInput
      label="New Image - Via Url"
      source={'imageUrl'}
      options={{ fullWidth: true }}
      validate={url({ allowBlank: true })}
    />
    <ArrayInput source="categories">
      <SimpleFormIterator>
        <TextInput label="Category" format={sanitizer} />
      </SimpleFormIterator>
    </ArrayInput>

    <CheckboxGroupInput
      source="placements"
      label="Placements"
      choices={[
        { id: 'forward_mail', name: 'FYM' },
        { id: 'splash_page', name: 'Splash Page' },
        { id: 'exclusive_offers', name: 'Exclusive Offers' },
        { id: 'owner_only', name: 'Owner Only' },
        { id: 'moving', name: 'Moving' }
      ]}
    />

    <BooleanInput source="metadata.exclusive" label="Exclusive" />
    <BooleanInput
      label="Contact Preference Required"
      source="metadata.contactPreferenceRequired"
    />
  </Fragment>
)

const PromotionDetailInput = withStyles(inputStyle)(({ classes }) => (
  <Fragment>
    <TextInput source="metadata.displayName" label="Display Name" fullWidth />
    <TextInput source="metadata.discount" label="Discount" />
    <TextInput source="metadata.description" label="Description" fullWidth />
    <TextInput source="metadata.shortTerms" label="Short Terms" fullWidth />
    <TextInput multiline source="metadata.fullTerms" label="Full Terms" />
    <div className={classes.input_container}>
      <div className={classes.input_title}>Redemption</div>
      <div className={classes.input_container}>
        <div className={classes.input_title}>Code</div>
        <SelectInput
          label="Type"
          source="redemption.code.type"
          choices={objectifyOptions([
            '',
            'static_promo_code',
            'confirmation_code',
            'dynamic_class',
            'none'
          ])}
          fullWidth
        />
        <TextInput label="Value" source="redemption.code.value" fullWidth />
      </div>

      <div className={classes.input_container}>
        <div className={classes.input_title}>Methods</div>
        <ArrayInput source="redemption.methods" label="Methods" fullWidth>
          <SimpleFormIterator>
            <SelectInput
              label="Type"
              source="type"
              choices={objectifyOptions([
                '',
                'location',
                'website',
                'informational'
              ])}
            />
            <TextInput multiline label="Value" source="value" />
            <TextInput multiline label="Description" source="description" />
          </SimpleFormIterator>
        </ArrayInput>
      </div>
    </div>
  </Fragment>
))

const PromotionRuleslInput = withStyles(inputStyle)(() => (
  <Fragment>
    <ArrayInput source="rules" fullWidth>
      <SimpleFormIterator>
        <TextInput disabled label="ID" source="id" />
        <FormDataConsumer>
          {({ getSource, scopedFormData, ...rest }) =>
            scopedFormData ? (
              <TextInput
                {...rest}
                label="External ID"
                source={getSource('externalId')}
                initialValue={
                  scopedFormData.externalIds && scopedFormData.externalIds[0]
                }
              />
            ) : null
          }
        </FormDataConsumer>
        <TextInput multiline label="Description" source="description" />
        <TextInput multiline label="Definition" source="definition" />
      </SimpleFormIterator>
    </ArrayInput>
  </Fragment>
))

export const CreatePromotion = props => (
  <Create title="Create Promotion" {...props}>
    <TabbedForm>
      <FormTab label="Settings">
        <ReferenceInput
          label="For Entity"
          source="forEntityId"
          reference="companies"
          filterToQuery={searchText => ({
            full_text: searchText,
            kind: 'business_partner'
          })}
          filter={{ kind: 'business_partner' }}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput source="name" optionText="name" />
        </ReferenceInput>
        <PromotionSettingsInput />
      </FormTab>
      <FormTab label="Detail">
        <PromotionDetailInput />
      </FormTab>
      <FormTab label="Rules">
        <PromotionRuleslInput />
      </FormTab>
    </TabbedForm>
  </Create>
)

const PromotionEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <UpdaterCloneButton
      record={data}
      omitFields={['externalIds']}
      basePath={basePath}
    />
  </TopToolbar>
)

export const EditPromotion = props => (
  <Edit title="Edit Promotion" {...props} actions={<PromotionEditActions />}>
    <TabbedForm>
      <FormTab label="Settings">
        <TextInput disabled label="Id" source="id" />
        <PromotionSettingsInput />
      </FormTab>
      <FormTab label="Detail">
        <PromotionDetailInput />
      </FormTab>
      <FormTab label="Rules">
        <PromotionRuleslInput />
      </FormTab>
    </TabbedForm>
  </Edit>
)
