import React, { Fragment } from 'react'
import { Card, CardHeader, CardContent } from '@material-ui/core'

export const formatPrice = ({ price, decimalPlaces = 2 }) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(price)

export default function PackageInfo({
  record: { offer = {}, price = {} } = {}
}) {
  const planPrice = (price.plan || {}).price

  const monthlyItems = (Object.values(price.items) || []).filter(
    item => item.billing_frequency === 'month'
  )
  const totalFees = monthlyItems
    .map(({ price }) => price)
    .reduce((sum, value) => sum + value, 0)

  const monthlyTotal = totalFees + planPrice

  return (
    <Fragment>
      <Card style={{ marginTop: 20 }}>
        <CardHeader title="Package Info" />
        <CardContent>
          <div>Provider: {offer.provider.name}</div>
          <div>Package: {offer.name}</div>
          <div>
            Regular Price: ${Math.round(offer.price * 100) / 100} / {offer.term}
          </div>
          {offer.introductory_price && (
            <div>
              Promo Price: $
              {Math.round(offer.introductory_price.price * 100) / 100}
              &nbsp;/&nbsp;{offer.introductory_price.term} for&nbsp;
              {offer.introductory_price.duration}{' '}
              {offer.introductory_price.term}s
            </div>
          )}
          {planPrice && (
            <div>
              Total Monthly Fees: {formatPrice({ price: monthlyTotal })} /{' '}
              {offer.term}
            </div>
          )}
        </CardContent>
      </Card>
    </Fragment>
  )
}
