import React from 'react'
import { Resource } from 'react-admin'
import { CompanyCreate, CompanyEdit, CompanyList } from './components/companies'
import {
  PromotionsList,
  EditPromotion,
  CreatePromotion
} from './components/promotions'
import {
  UtilitiesList,
  CreateUtility,
  EditUtility
} from './components/utilities'
import {
  UtilityPostalCodesList,
  CreateUtilityPostalCode,
  EditUtilityPostalCode
} from './components/utilityPostalCodes'
import {
  EntityConfigurationCreate,
  EntityconfigurationEdit,
  EntityconfigurationList
} from './components/entityConfigurations'
import {
  EntityItemDefinitionsList,
  CreateEntityItemDefinition,
  EditEntityItemDefinition
} from './components/entityItemDefinitions'
import HomeServicesOrderList from './components/homeServicesOrder/list'
import EditHomeServicesOrder from './components/homeServicesOrder/edit'
import { InviteList, InviteEdit, InviteCreate } from './components/invites'
import {
  FullServiceMoveList,
  FullServiceMoveEdit,
  FullServiceMoveCreate
} from './components/fullServiceMove'
import {
  DivisionCreate,
  DivisionEdit,
  DivisionList
} from './components/divisions'
import { ManagerList, ManagerUpsert } from './components/managers'
import AdminList from './components/admins/list'
import { AdminCreate, AdminEdit } from './components/admins/upsert'
import {
  ProfessionalCreate,
  ProfessionalEdit,
  ProfessionalList
} from './components/professionals'

import BusinessIcon from '@material-ui/icons/Business'
import WorkIcon from '@material-ui/icons/Work'
import StoreIcon from '@material-ui/icons/Store'
import MoneyIcon from '@material-ui/icons/AttachMoney'
import EmailIcon from '@material-ui/icons/Email'
import TruckIcon from '@material-ui/icons/LocalShipping'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt'
import PinDropIcon from '@material-ui/icons/PinDrop'
import BuildIcon from '@material-ui/icons/Build'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import CloudUpload from '@material-ui/icons/CloudUpload'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import {
  permissions as BulkImportPermissions,
  actions as BulkImportActions
} from './components/bulkImport'

export const resources = [
  <Resource
    name="companies"
    key="companies"
    list={CompanyList}
    edit={CompanyEdit}
    create={CompanyCreate}
    icon={StoreIcon}
    permission="company"
  />,
  <Resource
    name="divisions"
    key="divisions"
    list={DivisionList}
    edit={DivisionEdit}
    create={DivisionCreate}
    icon={BusinessIcon}
    permission="division"
  />,
  <Resource
    name="invites"
    key="invites"
    list={InviteList}
    edit={InviteEdit}
    create={InviteCreate}
    icon={EmailIcon}
    permission="invite"
    fullUpdateFields="metadata"
  />,
  <Resource
    name="relocation/appointments"
    key="full_service_move"
    options={{ label: 'Local Move Slots' }}
    list={FullServiceMoveList}
    edit={FullServiceMoveEdit}
    create={FullServiceMoveCreate}
    icon={TruckIcon}
    permission="user"
    fullUpdateFields="metadata"
  />,
  <Resource
    name="managers"
    key="managers"
    list={ManagerList}
    edit={ManagerUpsert}
    icon={GroupAddIcon}
    permission="user"
  />,
  <Resource
    name="admins"
    key="admins"
    list={AdminList}
    create={AdminCreate}
    edit={AdminEdit}
    icon={GroupAddIcon}
    permission="admin"
  />,
  <Resource
    name="professionals"
    key="professionals"
    list={ProfessionalList}
    edit={ProfessionalEdit}
    create={ProfessionalCreate}
    icon={WorkIcon}
    permission="professional"
  />,
  <Resource
    name="promotions"
    key="promotions"
    list={PromotionsList}
    edit={EditPromotion}
    create={CreatePromotion}
    icon={MoneyIcon}
    permission="promotion"
  />,
  <Resource
    name="utilities"
    key="utilities"
    list={UtilitiesList}
    create={CreateUtility}
    edit={EditUtility}
    icon={OfflineBoltIcon}
    permission="utility_provider"
  />,
  <Resource
    name="utilityPostalCodes"
    key="utilityPostalCodes"
    list={UtilityPostalCodesList}
    create={CreateUtilityPostalCode}
    edit={EditUtilityPostalCode}
    icon={PinDropIcon}
    options={{
      label: 'Utility Postal Codes'
    }}
    permission="utility_provider"
  />,
  <Resource
    name="entityItemDefinitions"
    key="entityItemDefinitions"
    list={EntityItemDefinitionsList}
    create={CreateEntityItemDefinition}
    edit={EditEntityItemDefinition}
    icon={BuildIcon}
    options={{
      label: 'Entity Item Definitions'
    }}
    permission="entity_item_definition"
  />,
  <Resource
    name="item_definitions"
    key="item_definitions"
    permission="entity_item_definition"
  />,
  <Resource
    name="home_services_order"
    key="home_services_order"
    list={HomeServicesOrderList}
    edit={EditHomeServicesOrder}
    icon={CloudUpload}
    options={{
      label: 'TV/Internet Orders'
    }}
    permission="home_services_order"
  />,
  <Resource
    name="bulk-import"
    key="bulk-import"
    options={{
      label: 'Bulk Import'
    }}
    icon={CloudUpload}
    permissions={BulkImportPermissions}
    actions={BulkImportActions}
  />,
  <Resource
    name="set-password"
    key="set-password"
    // Don't require any permissions for this route
    permissions={[]}
    actions={[]}
  />,
  <Resource name="roles" key="roles" permission="role" />,
  <Resource name="entities" key="entities" permission="entity" />,
  <Resource
    name="entity_configurations"
    key="entity_configurations"
    create={EntityConfigurationCreate}
    list={EntityconfigurationList}
    edit={EntityconfigurationEdit}
    //TODO: sort permission for entity_configuration
    permission="entity"
    icon={CompareArrowsIcon}
    options={{
      label: 'Entity Integrations'
    }}
  />
]
