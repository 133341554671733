import React from 'react'
import { Route } from 'react-router-dom'
import BulkImport from './components/bulkImport'
import SetPassword from './components/setPassword'

export default [
  <Route exact key="bulk" path="/bulk-import" component={BulkImport} />,
  <Route
    exact
    key="set-password"
    path="/set-password/:token"
    component={SetPassword}
    noLayout
  />
]
