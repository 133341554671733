import { BooleanInput, TextInput } from 'react-admin'
import React, { Fragment } from 'react'
import { url } from 'redux-form-validators'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import { withStyles } from '@material-ui/core'
import { LogoUploadInput } from './logo_upload'

const style = {
  details: {
    display: 'block'
  }
}

export const HomeownerInsuranceInput = withStyles(style)(
  ({ classes, record }) => (
    <Fragment>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          Homeowner Insurance
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <BooleanInput
            label="Required"
            source="config.homeowner_insurance.required"
          />
          <TextInput
            label="Provider Name"
            source="config.homeowner_insurance.name"
            options={{ fullWidth: true }}
          />
          <TextInput
            label="Description"
            source="config.homeowner_insurance.description"
            options={{ fullWidth: true }}
          />
          <TextInput
            label="CTA Link"
            source="config.homeowner_insurance.cta_url"
            options={{ fullWidth: true }}
            validate={url({ allowBlank: true })}
          />
          <LogoUploadInput
            record={(record.config || {}).homeowner_insurance || {}}
            sourcePrefix="config.homeowner_insurance"
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Fragment>
  )
)
