import { useState, useRef, useEffect } from 'react'

export function useScript(
  scriptOptions = {
    async: true
  },
  options = {
    removeOnUnmount: false
  }
) {
  const cachedScriptStatuses = useRef({})
  const [status, setStatus] = useState(() => {
    if (!scriptOptions.src) {
      return 'idle'
    }

    return 'loading'
  })

  useEffect(() => {
    if (!scriptOptions.src) return

    const cachedScriptStatus = cachedScriptStatuses.current[scriptOptions.src]
    if (cachedScriptStatus === 'ready' || cachedScriptStatus === 'error') {
      setStatus(cachedScriptStatus)
      return
    }

    let script = document.querySelector(`script[src="${scriptOptions.src}"]`)

    if (!script) {
      script = document.createElement('script')

      Object.assign(script, scriptOptions)

      document.body.appendChild(script)
    }

    const handleScriptStatus = event => {
      const newStatus = event.type === 'load' ? 'ready' : 'error'
      setStatus(newStatus)
      cachedScriptStatuses.current[scriptOptions.src] = newStatus
    }

    script.addEventListener('load', handleScriptStatus)
    script.addEventListener('error', handleScriptStatus)

    return () => {
      script?.removeEventListener('load', handleScriptStatus)
      script?.removeEventListener('error', handleScriptStatus)

      if (options?.removeOnUnmount === true) {
        script?.remove()
      }
    }
  }, [scriptOptions, options])

  return status
}
