export class BaseProcessor {
  constructor(endpoint, fields) {
    this.endpoint = endpoint
    // always exclude validation_errors
    this.fields = { ...fields, validation_errors: { excluded: true } }
  }

  validatedData(data) {
    return data.map((row, index) => {
      const validationErrors = []

      if (index === 0) {
        const headers = Object.keys(row)
        const requiredHeaders = Object.keys(this.fields).filter(
          k => this.fields[k].required
        )
        const missingHeaders = requiredHeaders.filter(k => !headers.includes(k))

        if (missingHeaders.length !== 0) {
          throw `The following required headers are missing (${missingHeaders.join(
            ','
          )})`
        }
      }

      //go through the headers and make sure required cols are present

      //go through each row and make sure required columns are not blank
      Object.keys(row).forEach(col => {
        if (this.fields[col] === undefined) {
          delete row[col]
          return
        }

        if (row[col] == null && this.fields[col].required) {
          validationErrors.push(`${col} is required`)
        }

        if (row[col] != null && this.fields[col].validate !== undefined) {
          let validateError = this.fields[col].validate(row[col])
          if (validateError instanceof Function)
            validateError = validateError(row[col])
          if (validateError === false)
            validationErrors.push(`${col} Failed Validation.`)
          else if (validateError)
            validationErrors.push(`${row[col]} ${validateError}`)
        }
      })

      row.validation_errors = validationErrors.join(' -- ')
      row.meta = { row_id: index }

      return row
    })
  }

  dynamicTypingCheck(field) {
    return this.fields[field] === undefined ||
      this.fields[field].dynamicTyping === undefined
      ? true
      : this.fields[field].dynamicTyping
  }

  transformedData(data) {
    const transformedData = []
    data.forEach(row => {
      const transformedRow = {}
      Object.keys(row).forEach(key => {
        const transformKey = this.setTransformKey(key, row)
        const transformedValue = this.transformValue(key, row)
        if (transformKey) {
          if (transformKey.includes('.')) {
            this.buildNestedObject(
              transformedValue,
              transformedRow,
              transformKey.split('.')
            )
          } else {
            transformedRow[transformKey] = transformedValue
          }
        } else {
          if (!this.ignoredKey(key)) {
            transformedRow[key] = transformedValue
          }
        }
      })
      transformedData.push(transformedRow)
    })
    return transformedData
  }

  setTransformKey(key, row) {
    if (this.fieldHasKeyOverride(key)) {
      return typeof this.fields[key].key === 'function'
        ? this.fields[key].key(row)
        : this.fields[key].key
    }
  }

  transformValue(key, row) {
    const value = row[key]
    const transformation = (this.fields[key] || {})['value']
    return transformation ? transformation(value, row) : value
  }

  buildNestedObject(transformedValue, transformedRow, transformKeys) {
    const key = transformKeys.shift()
    let value = transformedRow[key] || {}
    if (transformKeys.length === 0) {
      value = transformedRow
      value[key] = transformedValue
      return value
    }
    transformedRow[key] = this.buildNestedObject(
      transformedValue,
      value,
      transformKeys
    )
    return transformedRow
  }

  fieldHasKeyOverride(key) {
    return this.fields[key] && this.fields[key].key
  }

  ignoredKey(key) {
    return this.fields[key] && this.fields[key].excluded
  }
}
