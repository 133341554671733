import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE
} from 'react-admin'
import { BULK_PROCESS } from '../../actions/bulkProcess'
import qs from 'qs'
import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
// import pluralize from 'pluralize'
const pluralize = require('pluralize')
import { observableDiff, applyChange } from 'deep-diff'
import { FEATURES } from '../../components/constants/features'
import { resources } from '../../resources'
const RELATIONSHIP_DEPTH = 3

const listAssociations = {
  divisions: ['address'],
  home_services_order: ['agent', 'move'],
  // hard code includes since 'professional.user' is referencing professional therefore creates an infinite loop
  invites: ['from_address', 'to_address', 'professional', 'company'],
  entities: []
}
function setValueByArray(obj, parts, value) {
  if (!parts) {
    throw 'No parts array passed in'
  }

  if (parts.length === 0) {
    throw 'parts should never have a length of 0'
  }

  if (parts.length === 1) {
    obj[parts[0]] = value
  } else {
    const next = parts.shift()

    if (!obj[next]) {
      obj[next] = {}
    }
    setValueByArray(obj[next], parts, value)
  }
}

const restApiTransforms = (response, type, resource) => {
  if (type === GET_ONE && resource === 'companies') {
    const clonedResponse = cloneDeep(response)
    FEATURES.forEach(feature => {
      if (Object.hasOwnProperty.call(feature, 'defaultValue')) {
        const value = get(response, `data.config.features.${feature.key}`)
        if (value === undefined) {
          set(
            clonedResponse,
            `data.config.features.${feature.key}`,
            feature.defaultValue
          )
        }
      }
    })
    return clonedResponse
  }
  return response
}

const isFullUpdateField = (resourceName, path) => {
  const [resource] = resources.filter(r => r.props.name === resourceName)
  const {
    props: { fullUpdateFields }
  } = resource

  const fields = fullUpdateFields ? fullUpdateFields.split(/,\s*/) : []

  // Only look at the root for now
  return fields.filter(f => f === path[0]).length > 0
}

export default (apiUrl, httpClient, transformResponse = restApiTransforms) => {
  const generateQuery = (type, params, resource) => {
    let query
    if ([GET_MANY_REFERENCE, GET_LIST].includes(type)) {
      if (!params) return ''
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      query = {
        page: { number: page, size: perPage }
      }
      Object.keys(params.filter).forEach(key => {
        const filterField = `filter[${key}]`
        query[filterField] = params.filter[key]
      })
      if (type === 'GET_MANY_REFERENCE') {
        const targetFilter = `filter[${params.target}]`
        query[targetFilter] = params.id
      }
      if (order === 'ASC') {
        query.sort = field
      } else {
        query.sort = `-${field}`
      }
    } else if (type === GET_MANY) {
      return params.ids
        .map(value => qs.stringify({ 'filter[id][]': value }))
        .join('&')
    }

    if (type === GET_ONE && resource === 'home_services_order') {
      query = {
        allow_sensitive: true
      }
    }

    if (type === GET_LIST && listAssociations.hasOwnProperty(resource)) {
      query.include = listAssociations[resource].join(',')
    }

    return qs.stringify(query, { arrayFormat: 'brackets' })
  }
  /**
   * @param {string} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {string} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertRESTRequestToHTTP = (type, resource, params) => {
    const path = `${apiUrl}/${resource}`
    const options = {}
    let url
    switch (type) {
      case GET_MANY_REFERENCE:
      case GET_LIST:
        url = `${path}?${generateQuery(type, params, resource)}`
        break
      case GET_ONE:
        url = `${path}/${params.id}?${generateQuery(type, params, resource)}`
        break
      case GET_MANY:
        url = `${path}?${generateQuery(type, params, resource)}`
        break
      case UPDATE:
        url = `${path}/${params.id}`
        options.method = 'PATCH'
        // we don't accept JSON-API updates, so use simple json
        // the switch for headers is in the httpClient
        const { previousData, data } = params

        if (previousData) {
          const diff = {}
          observableDiff(previousData, data, change => {
            if (isFullUpdateField(resource, change.path)) {
              const [key] = change.path
              diff[key] = data[key]
              return
            }

            // check to see if any part of array was modified
            const numericIndex = change.path
              ? change.path.findIndex(i => !isNaN(i))
              : -1
            if (change.kind === 'A' || numericIndex >= 0) {
              const firstArrayPath =
                numericIndex >= 0
                  ? change.path.slice(0, numericIndex)
                  : change.path
              // we want to keep entire changes to the array so we don't lose existing values.
              setValueByArray(
                diff,
                firstArrayPath,
                firstArrayPath.reduce((prev, curr) => prev && prev[curr], data)
              )
              //diff.send(change.path) = data.send(change.path)
            } else {
              applyChange(diff, true, change)
            }
          })
          options.body = JSON.stringify(diff)
        } else {
          options.body = JSON.stringify(data)
        }
        break
      case BULK_PROCESS:
      case CREATE:
        url = path
        options.method = 'POST'
        // we don't accept JSON-API creates, so use simple json
        // the switch for headers is in the httpClient
        options.body = JSON.stringify(params.data)
        break
      case DELETE:
        url = params && params.id ? `${path}/${params.id}` : path
        options.method = 'DELETE'
        break
      default:
        throw new Error(`Unsupported fetch action type ${type}`)
    }
    return { url, options }
  }

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {string} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {string} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} REST response
   */
  const convertHTTPResponseToREST = (response, type) => {
    const { json } = response
    switch (type) {
      case GET_MANY_REFERENCE:
      case BULK_PROCESS:
      case GET_LIST:
      case GET_MANY:
        return convertList(json)
      case UPDATE:
      case CREATE:
        // Hack so that it takes non jsonAPI response
        return { data: convertHTTPResource(json.data, json.included) || json }
      case DELETE:
        return { data: { id: 'stub' } }
      default:
        return {
          data: convertHTTPResource(json.data, json.included)
        }
    }
  }

  const convertHTTPResource = (jsonResource, jsonIncluded) => {
    if (!jsonResource) return
    const resource = {
      id: jsonResource.id,
      ...jsonResource.attributes
    }
    return resolveResourceRelationships(resource, jsonResource, jsonIncluded)
  }

  const resolveResourceRelationships = (
    resource,
    jsonResource,
    jsonIncluded,
    depth = 0
  ) => {
    depth++
    if (depth > RELATIONSHIP_DEPTH) return resource
    if (jsonResource.relationships && jsonIncluded) {
      Object.keys(jsonResource.relationships).forEach(key => {
        const relationshipData = jsonResource.relationships[key]['data']
        // ensure the included objects data field is not null
        if (relationshipData) {
          // check to see if the relationship data is an array first, since typeof Array === 'object', not array
          if (Array.isArray(relationshipData) && relationshipData.length > 0) {
            resolveRelationshipArray(
              relationshipData,
              jsonIncluded,
              resource,
              key,
              depth
            )
            // if not an array, check to see if its a standard object
          } else if (typeof relationshipData === 'object') {
            resolveRelationshipObject(
              relationshipData,
              jsonIncluded,
              resource,
              key,
              depth
            )
          }
        }
      })
    }
    return resource
  }

  const resolveRelationshipArray = (
    relationshipData,
    jsonIncluded,
    resource,
    key,
    depth = 0
  ) => {
    relationshipData.forEach(relationship => {
      const foundRelationship = jsonIncluded.find(
        included =>
          included.id === relationship['id'] &&
          included.type === relationship['type']
      )
      if (foundRelationship) {
        const relationshipIds = `${pluralize.singular(key)}_ids`
        resource[relationshipIds] = resource[relationshipIds] || []
        resource[relationshipIds].push(relationship.id)
        resource[key] = resource[key] || []
        resource[key].push(
          resolveResourceRelationships(
            {
              id: foundRelationship.id,
              ...foundRelationship.attributes
            },
            foundRelationship,
            jsonIncluded,
            depth
          )
        )
      }
    })
  }

  const resolveRelationshipObject = (
    relationshipData,
    jsonIncluded,
    resource,
    key,
    depth = 0
  ) => {
    const foundRelationship = jsonIncluded.find(
      included =>
        included.id === relationshipData['id'] &&
        included.type === relationshipData['type']
    )
    if (foundRelationship) {
      resource[key] = resolveResourceRelationships(
        {
          id: foundRelationship.id,
          ...foundRelationship.attributes
        },
        foundRelationship,
        jsonIncluded,
        depth
      )
    }
  }

  const convertList = json => {
    if (!('data' in json)) {
      return json
    }
    const jsonData = json.data.map
      ? json.data.map(jsonResource => {
          const resource = convertHTTPResource(jsonResource, json.included)
          return resource
        })
      : convertHTTPResource(json.data, json.included)
    const totalRecords = json.meta?.pagination
      ? json.meta.pagination.total_records
      : jsonData.length
    return { data: jsonData, total: totalRecords, meta: json.meta }
  }

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a REST response
   */
  return async (type, resource, params) => {
    const { url, options } = convertRESTRequestToHTTP(type, resource, params)
    const response = await httpClient(url, options)
    const restResponse = convertHTTPResponseToREST(response, type)
    const result = transformResponse(restResponse, type, resource, params)
    return result
  }
}
