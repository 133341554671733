import { useFormState } from 'react-final-form'
import { connect } from 'react-redux'
import {
  TextInput,
  Edit,
  SimpleForm,
  SelectInput,
  showNotification,
  fetchEnd,
  fetchStart,
  useRefresh,
  UPDATE
} from 'react-admin'
import { Grid, Button, withStyles } from '@material-ui/core'
import React, { Fragment, useState, useCallback, useEffect } from 'react'
import { STATUSES, ERROR_TYPES } from './constants'
import { getPreviousErrorType } from './helpers'
import Modal from './components/modal'
import { updJsonApiDataProvider } from '../../utils/upd-data-provider/dataProvider'
import { OrderView as XfinityOrderView } from './components/xfinity/orderView'
import { OrderView as ATTOrderView } from './components/att/orderView'
import { OrderView as CoxOrderView } from './components/cox/orderView'
import { OrderView as SpectrumOrderView } from './components/spectrum/orderView'

/**
 * @typedef {import('./types').DTSPayload} Payload
 */

const orderViews = {
  xfinity: XfinityOrderView,
  att: ATTOrderView,
  cox: CoxOrderView,
  spectrum: SpectrumOrderView
}

const style = {
  error: {
    margin: '0.125em 0',
    color: 'red'
  },
  updateStatusButton: {
    marginLeft: '2em'
  },
  disabled: {
    '&, & + $label': {
      color: 'rgba(0, 0, 0, 0.65)'
    }
  },
  subtext: {
    color: '#5D5D5D',
    fontStyle: 'italic'
  },
  optionSubtext: {
    color: '#5D5D5D',
    fontStyle: 'italic',
    margin: '0 0 1em 1.8em',
    maxWidth: '33em'
  },
  label: {}
}

const EditOrderTitle = ({ record = {} }) => (
  <h1>Reservation Code: {record.reservation_code}</h1>
)

const ShowOrder = withStyles(style)(({ classes, record }) => {
  const {
    offer: {
      provider: { id: providerCode }
    }
  } = record

  const OrderView = orderViews[providerCode]
  return (
    <Grid container direction="row" justifyContent="center" spacing={8}>
      <OrderView record={record} classes={classes} />
    </Grid>
  )
})

const EditOrderStatus = withStyles(style)(({ classes, submit }) => {
  const formState = useFormState()
  const {
    values: {
      status,
      offer: {
        provider: { id: providerCode }
      }
    }
  } = formState

  const statuses = STATUSES[providerCode] || {}
  const errorTypes = ERROR_TYPES[providerCode] || {}

  const statusReasons = formState.values.status_reasons || {}
  const [open, setOpen] = useState(false)
  const [showError, setError] = useState(false)
  const handleOpen = () => {
    setError(false)
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  const handleSubmit = () => {
    submit()
  }

  const handleUpdate = useCallback(() => {
    if (statuses.errorRequired.includes(status)) {
      handleOpen()
    } else {
      handleSubmit()
    }
  }, [formState.values, handleOpen])

  const handleSave = useCallback(() => {
    const { error_type, note } = statusReasons[status] || {}
    if (!error_type) return
    const noteRequired =
      statuses.noteRequired.includes(status) || error_type === 'other'
    const noteMissing = noteRequired && !(note && note.trim())

    if (!noteMissing) {
      handleSubmit()
      setOpen(false)
    } else {
      setError(true)
    }
  }, [formState.values, setOpen, setError])

  const errorTypeOptions =
    status === 'cancelled' ? errorTypes.cancelled : errorTypes.pending

  const previousErrorType = getPreviousErrorType({ statusReasons, statuses })

  return (
    <Fragment>
      <SelectInput
        source="status"
        choices={statuses.all.map(status => ({
          id: status,
          name: status
        }))}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdate}
        disabled={statuses.uneditable.includes(status)}
        className={classes.updateStatusButton}
      >
        Update Status
      </Button>
      <Modal open={open} onClose={handleClose}>
        {status === 'completed_assisted' ? (
          <Fragment>
            <h4>{status} - Error type:</h4>
            <SelectInput
              source={`status_reasons.${status}.error_type`}
              choices={errorTypes.pending.map(({ id, name }) => ({
                id,
                name
              }))}
              initialValue={previousErrorType}
              label=""
            />
            <h4>How did you resolve the error:</h4>
            <TextInput
              multiline
              source={`status_reasons.${status}.note`}
              label=""
            />
            {showError && (
              <div className={classes.error}>
                Please specify how you resolved the error.
              </div>
            )}
            <Button variant="contained" color="primary" onClick={handleSave}>
              Submit
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <h4>{status} - Error type:</h4>
            <SelectInput
              source={`status_reasons.${status}.error_type`}
              choices={errorTypeOptions.map(({ id, name }) => ({
                id,
                name
              }))}
              label=""
            />
            <h4>Note:</h4>
            <TextInput
              multiline
              source={`status_reasons.${status}.note`}
              label=""
            />
            {showError && (
              <div className={classes.error}>
                Please specify the error in note
              </div>
            )}
            <Button variant="contained" color="primary" onClick={handleSave}>
              Submit
            </Button>
          </Fragment>
        )}
      </Modal>
    </Fragment>
  )
})

const OrderActions = ({ fetchStart, fetchEnd, showNotification }) => {
  const formState = useFormState()
  const refresh = useRefresh()

  const { id } = formState.values

  const submit = useCallback(
    forcedData => {
      const data = {
        status: formState.values.status,
        status_reasons: formState.values.status_reasons,
        ...forcedData
      }

      if (!forcedData && formState.pristine) return

      fetchStart()

      updJsonApiDataProvider()(UPDATE, 'home_services_order', {
        id,
        data
      })
        .then(() => {
          showNotification('Status successfully updated')
          refresh()
        })
        .catch(error => {
          showNotification(
            `Failed to update status:  ${error.message}`,
            'error'
          )
        })
        .finally(() => {
          fetchEnd()
        })
    },
    [formState.values, refresh, fetchStart, fetchEnd, showNotification]
  )

  return (
    <Grid
      container
      direction="row"
      alignItems="baseline"
      justifyContent="space-between"
    >
      <Grid item xs={10}>
        <EditOrderStatus
          fetchStart={fetchStart}
          fetchEnd={fetchEnd}
          submit={submit}
        />
      </Grid>
    </Grid>
  )
}

const EditHomeServicesOrder = props => {
  const { fetchStart, fetchEnd, showNotification, loading, ...restProps } =
    props

  return (
    <Edit title="Process Order" {...restProps}>
      <SimpleForm
        redirect={false}
        toolbar={null}
        initialValues={{ status_reasons: {} }}
        onSubmit={() => {}}
      >
        <OrderActions
          showNotification={showNotification}
          fetchStart={fetchStart}
          fetchEnd={fetchEnd}
          loading={loading}
        />
        <EditOrderTitle />
        <ShowOrder />
      </SimpleForm>
    </Edit>
  )
}

const mapStateToProps = state => ({
  loading: state.admin.loading
})

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditHomeServicesOrder)
