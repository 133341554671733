import React from 'react'
import ReactIdleTimer from 'react-idle-timer'
import { connect } from 'react-redux'
import { DELETE, userLogout } from 'react-admin'
import { updJsonApiDataProvider } from '../utils/upd-data-provider/dataProvider'

// 1 day idle timeout - if the user is idle for the length
// of the timeout period, they will signed out of the app.
const IDLE_TIMEOUT = 1000 * 60 * 60 * 24

const IdleTimer = ({ userLogout }) => {
  const onIdle = () => {
    updJsonApiDataProvider()(DELETE, 'sessions').then(() => {
      userLogout('/')
    })
  }

  return <ReactIdleTimer onIdle={onIdle} timeout={IDLE_TIMEOUT} />
}

export default connect(undefined, { userLogout })(IdleTimer)
