import { Admin } from 'react-admin'
import React, { Component } from 'react'
import CustomLayout from './customLayout'
import Dashboard from './components/dashboard'
import LoginPage from './components/loginPage'
import {
  buildUPDGraphQLDataProvider,
  updJsonApiDataProvider,
  updConfigServiceDataProvider
} from './utils/upd-data-provider/dataProvider'
import authProvider from './utils/authProvider'
import customRoutes from './routes'
import { resources } from './resources'
import { hasPermission } from './utils/permission'

class App extends Component {
  fetchResources = permissions => {
    const permittedResources = resources.filter(resource => {
      const {
        props: {
          permission,
          permissions: requiredPermissions,
          actions,
          list,
          create,
          edit,
          delete: deleteAction
        }
      } = resource
      if (requiredPermissions && actions) {
        return requiredPermissions.reduce(
          (premitted, permission) =>
            premitted && hasPermission(permissions, permission, actions),
          true
        )
      } else {
        const requiredActions = []
        if (list) requiredActions.push('read')
        if (create) requiredActions.push('create')
        if (edit) requiredActions.push('update')
        if (deleteAction) requiredActions.push('destroy')

        return hasPermission(permissions, permission, requiredActions)
      }
    })

    return permittedResources
  }

  constructor() {
    super()
  }

  render() {
    const graphqlResources = [
      'promotions',
      'utilities',
      'entityItemDefinitions',
      'utilityPostalCodes'
    ]

    const dataProvider = async (type, resource, params) => {
      let provider
      if (graphqlResources.includes(resource)) {
        provider = await buildUPDGraphQLDataProvider()
      } else if (resource === 'entity_configurations') {
        provider = updConfigServiceDataProvider()
      } else {
        provider = updJsonApiDataProvider()
      }

      return await provider(type, resource, params)
    }

    return (
      <Admin
        dashboard={Dashboard}
        loginPage={LoginPage}
        authProvider={authProvider}
        dataProvider={dataProvider}
        layout={CustomLayout}
        customRoutes={customRoutes}
      >
        {this.fetchResources}
      </Admin>
    )
  }
}

export default App
